// Expected Parameters From URL = [
//   "fontColor",
//   "Mode",
//   "Set1",
//   "Set2",
//   "Type",
//   "LoginType",
//   "chatType",
//   "setUserTags",
//   "SuperAdminComId",
//   "cometchat_feedback_college",
//   "cometchat_broadcast_college",
//   "BrodCastIS",
//   "withTags",
//   "univ",
//   "prevUrl",
//   "isAmbChat"
// ]
export function getDataFromParams(key) {
  const params = new URLSearchParams(window.location.search);
  return params?.get(key) || '';
}
