export const listItem = (props) => {
	const selectedState =
		props.selectedConversation &&
		props.selectedConversation.conversationId ===
			props.conversation.conversationId
			? {
					backgroundColor: `${props.theme.backgroundColor.primary}`,
			  }
			: {};

	return {
		display: "flex",
		flexDirection: "row",
		justifyContent: "left",
		alignItems: "center",
		cursor: "pointer",
		width: "99%",
		padding: "8px 16px",
		position: "relative",
		...selectedState,
		"&:hover": {
			backgroundColor: `${props.theme.backgroundColor.primary}`,
		},
	};
};

export const itemThumbnailStyle = () => {
	return {
		display: "inline-block",
		width: "40px",
		height: "40px",
		flexShrink: "0",
	};
};

export const itemDetailStyle = () => {
	return {
		width: "calc(100% - 45px)",
		flexGrow: "1",
		paddingLeft: "16px",
		"&[dir=rtl]": {
			paddingRight: "16px",
			paddingLeft: "0",
		},
	};
};

export const itemRowStyle = () => {
	return {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "baseline",
	};
};

export const itemNameStyle = (props) => {
	return {
	  fontFamily: "Lexend Deca",
	  fontSize: "14px",
	  fontStyle: "normal",
	  fontWeight: 500,
	  lineHeight: "24px", // Corresponds to 171.429%
	  display: "block",
	  width: "calc(100% - 70px)",
	  overflow: "hidden",
	  textOverflow: "ellipsis",
	  whiteSpace: "nowrap",
	//   color: `${props.theme.color.primary}`, // Accepting the color from props
	  color: `#494F73 `, // Accepting the color from props
	  textTransform: "capitalize",
	  
	  
	};
  };
  

  export const itemLastMsgStyle = (props) => {
	return {
	  margin: "0",
	  fontFamily: "Lexend Deca",
	  fontSize: "14px", // Changed to 14px from the provided specs
	  fontStyle: "normal",
	  fontWeight: 300, // Light weight
	  lineHeight: "24px", // Corresponds to 171.429%
	  width: "calc(100% - 50px)",
	  overflow: "hidden",
	  textOverflow: "ellipsis",
	  whiteSpace: "nowrap",
	//   color: `${props.theme.color.helpText}`, // Using the provided color prop
	color: `#494F73 `, // Accepting the color from props

	};
  };
  

  export const itemLastMsgTimeStyle = (props) => {
	return {
	  width: "70px",
	  textAlign: "right",
	//   color: `${props.theme.color.helpText}`,
	color: `#494F73 `, // Accepting the color from props

	  
	  fontFamily: "Lexend Deca",
	  fontSize: "14px", // Updated font size to 14px as per provided spec
	  fontStyle: "normal",
	  fontWeight: 300, // Light weight
	  lineHeight: "24px", // Corresponds to 171.429%
	};
  };
  