// export const presenceStyle = (props) => {
// 	let presenceStatus = {
// 		backgroundColor: "#C4C4C4",
// 	};

// 	if (props.status === "online" || props.status === "available") {
// 		presenceStatus = {
// 			backgroundColor: "#3BDF2F",
// 		};
// 	}

// 	return {
// 		width: "9px",
// 		height: "9px",
// 		top: "-12px",
// 		float: "right",
// 		position: "relative",
// 		...presenceStatus,
// 	};
// };

export const presenceStyle = (props, context) => {
	let status = props?.member?.status;
	let type = context?.type;

	if (type === "group") {
	  let borderColor = "#C4C4C4"; // Default color for offline
	  let presenceStatus = {
		border: `2px solid ${borderColor}`,
	  };
  
	  if (status === "online" || status === "available") {
		borderColor = "#3BDF2F"; // Green color for online
		presenceStatus = {
		  border: `2px solid ${borderColor}`,
		};
	  }
  
	  return {
		width: "37px",
		height: "37px",
		borderRadius: "50%",
		position: "absolute",
		top: "-1px",
		right: "-1px",
		...presenceStatus,
	  };
	} else {
	  let presenceStatus = {
		backgroundColor: "#C4C4C4",
	  };
  
	  if (status === "online" || status === "available") {
		presenceStatus = {
		  backgroundColor: "#3BDF2F",
		};
	  }
  
	  return {
		width: "9px",
		height: "9px",
		top: "-12px",
		float: "right",
		position: "relative",
		...presenceStatus,
	  };
	}
  };
  