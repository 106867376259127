import React from "react";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import { CometChat } from "@cometchat/chat-sdk-javascript";

import { CometChatConversationList } from "../";
import { CometChatMessages } from "../../Messages";
import {
  CometChatIncomingCall,
  CometChatIncomingDirectCall,
} from "../../Calls";

import { CometChatContextProvider } from "../../../util/CometChatContext";
import * as enums from "../../../util/enums.js";

import Translator from "../../../resources/localization/translator";
import { theme } from "../../../resources/theme";

import {
  chatScreenStyle,
  chatScreenSidebarStyle,
  chatScreenMainStyle,
} from "./style";
import { getDataFromParams } from "../../../../getUrlParams.js";

// new varible to get tag to uer
const UserTag = getDataFromParams("setUserTags");

class CometChatConversationListWithMessages extends React.Component {
  loggedInUser = null;

  constructor(props) {
    super(props);

    this.state = {
      tab: "conversations",
      sidebarview: false,
      activeUserChat: "",
      checkconvo: true,
      supportid: getDataFromParams("Set2"),
      isSupport: getDataFromParams("Set2") != "",
      supportChatDeleted: false,
    };

    this.contextProviderRef = React.createRef();
    this.chatListRef = React.createRef();
  }
 

  updatecheckconvo = (value) => {
    //console.log("value=================>", value);
  
    // If UserTag is super-admin, always set checkconvo to true
    if (UserTag === "super-admin") {
      this.setState({ checkconvo: true }, () => {
        //console.log("Updated checkconvo state (super-admin):", this.state.checkconvo);
      });
    } else {
      // Otherwise, set checkconvo based on the passed value
      this.setState({ checkconvo: !!value }, () => {
        //console.log("Updated checkconvo state:", this.state.checkconvo);
      });
    }
  };
  

  componentDidMount() {
    // console.log("shouldShowInputBoxlistwithmessages",this.props.shouldShowInputBox)
    if (
      this.props.chatWithUser.length === 0 &&
      this.props.chatWithGroup.length === 0
    ) {
      this.toggleSideBar();
    }
    if (this.props.chatWithUser) {
      const currentActiveUID = this.props.chatWithUser;
      this.setState({ activeUserChat: currentActiveUID });
    }
    //console.log("=================<<===========", this.state.checkconvo,this.state);
    // console.log('Is Support page ==.......', this.state.isSupport, this.state.supportid)
  }

  itemClicked = (item, type) => {
    this.setState({ activeUserChat: item.uid });
    this.contextProviderRef.setTypeAndItem(type, item);
    this.toggleSideBar();
  };

  actionHandler = (action, item, count, ...otherProps) => {
    switch (action) {
      case enums.ACTIONS["TOGGLE_SIDEBAR"]:
        this.toggleSideBar();
        break;
      case enums.GROUP_MEMBER_SCOPE_CHANGED:
      case enums.GROUP_MEMBER_KICKED:
      case enums.GROUP_MEMBER_BANNED:
        this.groupUpdated(action, item, count, ...otherProps);
        break;
      default:
        break;
    }
  };

  toggleSideBar = () => {
    const sidebarview = this.state.sidebarview;
    this.setState({ sidebarview: !sidebarview });
  };

  /**
 If the logged in user is banned, kicked or scope changed, update the chat window accordingly
 */
  groupUpdated = (key, message, group, options) => {
    switch (key) {
      case enums.GROUP_MEMBER_BANNED:
      case enums.GROUP_MEMBER_KICKED: {
        if (
          this.contextProviderRef.type === CometChat.ACTION_TYPE.TYPE_GROUP &&
          this.contextProviderRef.item.guid === group.guid &&
          options.user.uid === this.loggedInUser.uid
        ) {
          this.contextProviderRef.setItem({});
          this.contextProviderRef.setType("");
        }
        break;
      }
      case enums.GROUP_MEMBER_SCOPE_CHANGED: {
        if (
          this.contextProviderRef.type === CometChat.ACTION_TYPE.TYPE_GROUP &&
          this.contextProviderRef.item.guid === group.guid &&
          options.user.uid === this.loggedInUser.uid
        ) {
          const newObject = Object.assign({}, this.contextProviderRef.item, {
            scope: options["scope"],
          });
          this.contextProviderRef.setItem(newObject);
          this.contextProviderRef.setType(CometChat.ACTION_TYPE.TYPE_GROUP);
        }
        break;
      }
      default:
        break;
    }
  };

  render() {
    let messageScreen = (
      <CometChatMessages
        theme={this.props.theme}
        lang={this.props.lang}
        _parent="conversations"
        actionGenerated={this.actionHandler}
        shouldShowInputBox={this.props.shouldShowInputBox}
        isChatingWithSupport={this.props.isChatingWithSupport}
        isRespondingAsAmbassador={this.props.isRespondingAsAmbassador}
        sidebarview={this.state.ch}
      />
    );

    return (
      <CometChatContextProvider
        ref={(el) => (this.contextProviderRef = el)}
        user={this.props.chatWithUser}
        group={this.props.chatWithGroup}
        language={this.props.lang}
      >
        <div
          css={chatScreenStyle(this.props)}
          className="cometchat cometchat--chats"
          dir={Translator.getDirection(this.props.lang)}
        >
          <div>
            {/* {this.state.checkconvo ? null : ( */}
              <div
                css={chatScreenSidebarStyle(this.state, this.props)}
                className="chats__sidebar"
              >
                <CometChatConversationList
                  ref={(el) => (this.chatListRef = el)}
                  _parent="clwm"
                  theme={this.props.theme}
                  lang={this.props.lang}
                  onItemClick={this.itemClicked}
                  currentUserSelected={
                    this.state.activeUserChat || this.props.chatWithUser
                  }
                  actionGenerated={this.actionHandler}
                  shouldOpenFirstChat={this.props.shouldOpenFirstChat}
                  updatecheckconvo={this.updatecheckconvo}
                  supportChatDelete={this.supportChatDelete}
                  sidebarview={this.state.checkconvo}
                />
              </div>
            {/* )} */}
          </div>
          <div
            css={chatScreenMainStyle(
              this.state,
              this.props,
              this.state.checkconvo
            )}
            className="chats__main"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {messageScreen}
          </div>
          <CometChatIncomingCall
            theme={this.props.theme}
            lang={this.props.lang}
            actionGenerated={this.actionHandler}
          />
          <CometChatIncomingDirectCall
            theme={this.props.theme}
            lang={this.props.lang}
            actionGenerated={this.actionHandler}
          />
        </div>
      </CometChatContextProvider>
    );
  }
}

// Specifies the default values for props:
CometChatConversationListWithMessages.defaultProps = {
  lang: Translator.getDefaultLanguage(),
  theme: theme,
  chatWithUser: "",
  chatWithGroup: "",
};

CometChatConversationListWithMessages.propTypes = {
  lang: PropTypes.string,
  theme: PropTypes.object,
  chatWithUser: PropTypes.string,
  chatWithGroup: PropTypes.string,
};

export { CometChatConversationListWithMessages };
