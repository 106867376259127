import { CometChat } from "@cometchat/chat-sdk-javascript";

import * as enums from "../../../util/enums.js";

export class GroupListManager {
	groupRequest = null;
	groupListenerId = "grouplist_" + new Date().getTime();

	constructor(searchKey) {
		if (searchKey) {
			const encryptedSearchKey = this.encryptName(searchKey);
			this.groupRequest = new CometChat.GroupsRequestBuilder()
				.setLimit(30)
				.setSearchKeyword(encryptedSearchKey)
				
				.build();
		} else {
			this.groupRequest = new CometChat.GroupsRequestBuilder()
				.setLimit(30)
				.build();
		}
	}

	encryptName(message){
        let shift = 3;
        let encryptedMessage = '';
        let specialValue = '#$^%&*#$';
        for (let i = 0; i < message.length; i++) {
        const charCode = message.charCodeAt(i);
        if (charCode >= 65 && charCode <= 90) {
            encryptedMessage += String.fromCharCode(
            ((charCode - 65 + shift) % 26) + 65
            );
        } else if (charCode >= 97 && charCode <= 122) {
            encryptedMessage += String.fromCharCode(
            ((charCode - 97 + shift) % 26) + 97
            );
        } else {
            encryptedMessage += message[i];
        }
        }
        return encryptedMessage + specialValue;
        // return NameOf

        // var CryptoJS = require("crypto-js");
        // return CryptoJS.AES.encrypt(NameOf,encryptionKey.trim()).toString();
    }

	fetchNextGroups() {
		return this.groupRequest.fetchNext();
	}

	attachListeners(callback) {
		CometChat.addGroupListener(
			this.groupListenerId,
			new CometChat.GroupListener({
				onGroupMemberScopeChanged: (
					message,
					changedUser,
					newScope,
					oldScope,
					changedGroup
				) => {
					callback(enums.GROUP_MEMBER_SCOPE_CHANGED, message, changedGroup, {
						user: changedUser,
						scope: newScope,
						// hasJoined: true,
					});
				},
				onGroupMemberKicked: (message, kickedUser, kickedBy, kickedFrom) => {
					callback(enums.GROUP_MEMBER_KICKED, message, kickedFrom, {
						user: kickedUser,
						hasJoined: false,
					});
				},
				onGroupMemberBanned: (message, bannedUser, bannedBy, bannedFrom) => {
					callback(enums.GROUP_MEMBER_BANNED, message, bannedFrom, {
						user: bannedUser,
						hasJoined: false,
					});
				},
				onGroupMemberUnbanned: (
					message,
					unbannedUser,
					unbannedBy,
					unbannedFrom
				) => {
					callback(enums.GROUP_MEMBER_UNBANNED, message, unbannedFrom, {
						user: unbannedUser,
						hasJoined: false,
					});
				},
				onMemberAddedToGroup: (
					message,
					userAdded,
					userAddedBy,
					userAddedIn
				) => {
					callback(enums.GROUP_MEMBER_ADDED, message, userAddedIn, {
						user: userAdded,
						hasJoined: true,
					});
				},
				onGroupMemberLeft: (message, leavingUser, group) => {
					callback(enums.GROUP_MEMBER_LEFT, message, group, {
						user: leavingUser,
					});
				},
				onGroupMemberJoined: (message, joinedUser, joinedGroup) => {
					callback(enums.GROUP_MEMBER_JOINED, message, joinedGroup, {
						user: joinedUser,
					});
				},
			})
		);
	}

	removeListeners() {
		CometChat.removeGroupListener(this.groupListenerId);
	}
}
