export const chatsWrapperStyle = (props, theme) => {
	const borderStyle =
		props._parent === ""
			? {
					border: `1px solid ${theme.borderColor.primary}`,
			  }
			: {};

	return {
		display: "flex",
		flexDirection: "column",
		height: "100%",
		boxSizing: "border-box",
		...borderStyle,
		"*": {
			boxSizing: "border-box",
			"::-webkit-scrollbar": {
				width: "8px",
				height: "4px",
			},
			"::-webkit-scrollbar-track": {
				background: "#ffffff00",
			},
			"::-webkit-scrollbar-thumb": {
				background: "#ccc",
				"&:hover": {
					background: "#aaa",
				},
			},
		},
	};
};

export const chatsHeaderStyle = (theme) => {
	return {
		padding: "15px",
		paddingRight:"0px",
		display: "flex",
		alignItems: "center",
		borderBottom: `1px solid ${theme.borderColor.primary}`,
		height: "65px",
	};
};

export const lineStyle = () => {
	return {
		width: "1px",
		height: "inherit",
		backgroundColor: "#eaeaea",
		// margin: "0px 15px",
		margin: "0px 8px",
	};
};

export const chatsHeaderCloseStyle = (img, theme) => {
	const mq = [...theme.breakPoints];

	return {
		cursor: "pointer",
		mask: `url(${img}) no-repeat left center`,
		backgroundColor: `#BBBCE0`,
		height: "24px",
		width: "24px",
		[`@media ${mq[0]}`]: {
			display: "block!important",
		},
	};
};

export const chatsHeaderTitleStyle = (props) => {
	const alignment =
		props.hasOwnProperty("enableCloseMenu") && props.enableCloseMenu.length > 0
			? {
					width: "33%",
					textAlign: "center",
			  }
			: {};

	return {
		color: '#494F73',
		margin: "0",
		display: "inline-block",
		fontFamily: 'Lexend Deca',
		width: "100%",
		textAlign: "left",
		fontSize: "16px",
		// fontSize: "22px",
		fontWeight: "700",
		lineHeight: "26px",
		...alignment,
		"&[dir=rtl]": {
			textAlign: "right",
		},
	};
};

export const chatsMsgStyle = () => {
	return {
		overflow: "hidden",
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		position: "absolute",
		top: "50%",
	};
};

export const chatsMsgTxtStyle = (theme) => {
	return {
		margin: "0",
		minHeight: "36px",
		color: `${theme.color.secondary}`,
		fontSize: "20px!important",
		fontWeight: "600",
		lineHeight: "30px",
		wordWrap: "break-word",
		padding: "0 16px",
	};
};

export const chatsListStyle = () => {
	return {
		height: "calc(100% - 75px)",
		width: "99%",
		overflowY: "scroll",
		margin: "0",
		padding: "0",
		paddingTop:"5px",

		'&::-webkit-scrollbar': {
			width: '3px', 
			borderRadius: '20px',
		},
		'&::-webkit-scrollbar-track': {
			background: '#a6a8d640', // Set the background color of the track
			marginTop: '5px', // Add some gap at the top
      		marginBottom: '0px', // Add some gap at the bottom
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: '#9093D8', // Set the color of the scrollbar thumb
			borderRadius: '20px', // Add rounded corners to the thumb
		},
	};
};
export const modalSearchStyle = () => {
	return {
		fontWeight: "normal",
		marginBottom: "16px",
		width: "100%",
		height: "35px",
		borderRadius: "8px",
		boxShadow: "rgba(20, 20, 20, 0.04) 0 0 0 1px inset",
		backgroundColor: "rgba(20, 20, 20, 0.04)",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	};
};

export const searchButtonStyle = (img, context) => {
	return {
		width: "30px",
		height: "100%",
		padding: "8px",
		cursor: "default",
		mask: `url(${img}) 10px center no-repeat`,
		backgroundColor: `${context.theme.secondaryTextColor}!important`,
	};
};

export const searchInputStyle = () => {
	return {
		width: "calc(100% - 30px)",
		height: "100%",
		padding: "8px",
		fontSize: "15px",
		outline: "none",
		border: "none",
		backgroundColor: "transparent",
	};
};

export const modalListStyle = (context) => {
	const mq = [...context.theme.breakPoints];

	return {
		height: "calc(100% - 125px)",
		overflowY: "auto",
		display: "flex",
		width: "100%",
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		[`@media ${mq[1]}, ${mq[2]}`]: {
			height: "100%",
		},
	};
};
export const contactSearchStyle = () => {
	return {
		margin: "16px",
		position: "relative",
		borderRadius: "8px",
		boxShadow: "rgba(20, 20, 20, 0.04) 0 0 0 1px inset",
		backgroundColor: "rgba(20, 20, 20, 0.04)",
		height: "35px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	};
};

export const contactSearchButtonStyle = (img, theme) => {
	return {
		width: "30px",
		height: "100%",
		padding: "8px 0 8px 8px",
		cursor: "default",
		mask: `url(${img}) 10px center no-repeat`,
		backgroundColor: `${theme.secondaryTextColor}!important`,
	};
};

export const contactSearchInputStyle = (props) => {
	return {
		width: "calc(100% - 30px)",
		padding: "8px",
		fontSize: "15px",
		fontWeight: "400",
		lineHeight: "20px",
		outline: "none",
		border: "none",
		height: "100%",
		color: props.theme.color.search,
		backgroundColor: "transparent",
	};
};
