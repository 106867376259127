export const actionMessageStyle = () => {
	return {
		padding: "8px 16px",
		marginBottom: "16px",
		textAlign: "center",
		height: "36px",
	};
};

export const actionMessageTxtStyle = () => {
	return {
		fontSize: "13.5px",
		margin: "0",
		lineHeight: "20px",
		color:"#494F73",
		fontWeight: 300,
		textTransform: "capitalize",

		
	};
};
export const userStyle = () => ({
	color: "#494F73",
	fontFamily: "Lexend Deca",
	fontSize: "13px",
	fontStyle: "normal",
	fontWeight: 600,
	lineHeight: "18px", // 150%
  });