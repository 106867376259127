import { getDataFromParams } from "../../../../getUrlParams";

export const chatComposerStyle = (context) => {
  return {
    // padding: "16px",
   backgroundColor: `${context.theme.backgroundColor.white}`,
    zIndex: "1",
    order: "3",
    position: "relative",
    flex: "none",
    padding: "15px 20px 10px 20px",
    boxShadow: "0px -10px 50px 0px rgba(0, 0, 0, 0.20)",
    bottom:"-3px",
    
  };
};

export const editPreviewContainerStyle = (context, keyframes) => {
  const slideAnimation = keyframes`
    from {
        bottom: -60px
    }
    to {
        bottom: 0px
    }`;

  return {
    padding: "7px",
    backgroundColor: `${context.theme.backgroundColor.white}`,
    borderColor: `${context.theme.borderColor.primary}`,
    borderWidth: "1px 1px 1px 5px",
    borderStyle: "solid",
    color: `${context.theme.color.helpText}`,
    fontSize: "13px",
    animation: `${slideAnimation} 0.5s ease-out`,
    position: "relative",
  };
};

export const previewHeadingStyle = () => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };
};

export const previewTextStyle = () => {
  return {
    padding: "5px 0",
  };
};

export const previewCloseStyle = (img, context) => {
  return {
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    cursor: "pointer",
    mask: `url(${img}) center center no-repeat`,
    backgroundColor: `${context.theme.primaryColor}`,
  };
};

export const composerInputStyle = () => {
  return {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "flex-end",
    position: "relative",
    zIndex: "2",
    padding: "0",
  };
};

// Helper function to convert hex color to RGBA
function hexToRGBA(hex, alpha) {
  // Convert hex to RGB
  var r = parseInt(hex.slice(1, 3), 16);
  var g = parseInt(hex.slice(3, 5), 16);
  var b = parseInt(hex.slice(5, 7), 16);

  // If alpha is not provided, set it to 1 (fully opaque)
  alpha = alpha || 1;

  // Return RGBA string
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export const inputInnerStyle = (props, state, context) => {
  const color = getDataFromParams("fontColor") ?? "#ffffff";
  const rgbaColor = hexToRGBA(color, 0.3);
  return {
    flex: "1 1 auto",
    position: "relative",
    outline: "none",
    border: `1px solid ${color}`,
    backgroundColor: "rgba(166, 168, 214, 0.15)",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    boxShadow: "0px 0px 8px 0px " + rgbaColor,
    borderRadius: "24px",
  };
};

export const messageInputStyle = (disabled) => {
  const disabledState = disabled
    ? {
        pointerEvents: "none",
        opacity: "0.4",
      }
    : {};

  return {
    width: "100%",
    outline: "none",
    // overflow: "hidden", // Hides scrollbar by default
    overflow:"auto",
    position: "relative",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    zIndex: "1",
    height: "21px",
    backgroundColor: "#00000000",
    border: "none",
    resize: "none",
    userSelect: "text",
    margin: "10px 8px 10px 20px",
    color: "#494f73",
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "400",
    ...disabledState,
    "&:empty:before": {
      content: "attr(placeholder)",
      color: "#494f73",
      pointerEvents: "none",
      display: "block", /* For Firefox */
    },
    "&::-webkit-scrollbar": {
      display: "none", // Ensures the scrollbar is not visible
    },
    "&::-webkit-scrollbar-thumb": {
      background: "transparent", // Makes the scrollbar thumb transparent
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent", // Makes the scrollbar track transparent
    },
  };
};

export const inputStickyStyle = (disabled, attachments, context) => {
  const disabledState = disabled
    ? {
        pointerEvents: "none",
      }
    : {};

  const flexDirectionProp =
    attachments === null
      ? {
          flexDirection: "row-reverse",
        }
      : {};

  return {
    display: "flex",
    alignSelf: "end",
    ...flexDirectionProp,
    ...disabledState,
    "&:empty:before": {
      pointerEvents: "none",
    },
  };
};

export const stickyAttachmentStyle = () => {
  return {
    display: "flex",
    width: "auto",
  };
};

export const attachmentIconStyle = () => {
  return {
    margin: "auto 0",
    width: "24px",
    height: "20px",
    cursor: "pointer",
  };
};

export const filePickerStyle = (state) => {
  const color = getDataFromParams("fontColor") ?? "#ffffff";
  const active = state.showFilePicker
    ? {
        width: "fit-content",
        opacity: "1",
      }
    : {};

  return {
    width: "0",
    borderRadius: "8px",
    overflow: "hidden",
    zIndex: "1",
    opacity: "0",
    transition: "width 0.5s linear",
    position: "absolute",
    bottom: "34px",
    right: "93px",
    backgroundColor: "#fff",
    border: "1px solid " + color,
    boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.20)",
    ...active,
  };
};

export const fileListStyle = () => {
  return {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "center",
    padding: "0px",
  };
};

export const fileItemStyle = (img, context) => {
  return {
    height: "50px",
    width: "150px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    margin: "0px",
    " div > i": {
      width: "24px",
      height: "24px",
      display: "inline-block",
      background: `url(${img}) center center no-repeat`,
      // backgroundColor: `${context.theme.secondaryTextColor}`,
    },
    " > input": {
      display: "none",
    },
  };
};

export const stickyAttachButtonStyle = (img, context) => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    " div > i": {
      width: "22px",
      height: "22px",
      display: "inline-block",
      mask: `url(${img}) center center no-repeat`,
      backgroundColor: "#494F73",
    },
  };
};

export const stickyButtonStyle = (state) => {
  const active = state.showFilePicker
    ? {
        display: "flex",
      }
    : {
        display: "flex",
      };

  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    width: "auto",
    ...active,
  };
};

export const emojiButtonStyle = (img, context) => {
  return {
    height: "24px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 0 0 2px",
    "div > i": {
      width: "22px",
      height: "22px",
      display: "inline-block",
      mask: `url(${img}) center center no-repeat`,
      backgroundColor: "#494F73",
    },
  };
};

export const hideItem = () => {
  return {
    display: "none",
  };
};

export const recorderButtonStyle = (img, context) => {
  const color = getDataFromParams("fontColor") ?? "#ffffff";
  return {
    height: "40px",
    width: "40px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 -1px 0 8px",
    background: color,
    borderRadius: "30px",
    i: {
      width: "24px",
      height: "24px",
      display: "inline-block",
      mask: `url(${img}) center center no-repeat`,
      backgroundColor: "#fff",
    },
  };
};

export const stopButtonStyle = (img, context) => {
  const color = getDataFromParams("fontColor") ?? "#ffffff";

  return {
   height: "40px",
    width: "40px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 -1px 0 8px",
    background: color,
    borderRadius: "30px",
    i: {
      width: "24px",
      height: "24px",
      display: "inline-block",
      mask: `url(${img}) center center no-repeat`,
      backgroundColor: "#fff",
    },
  };
};

export const deleteButtonStyle = (img, context) => {
  return {
    height: "24px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 0 0 8px",
    i: {
      width: "24px",
      height: "24px",
      display: "inline-block",
      mask: `url(${img}) center center no-repeat`,
      backgroundColor: `${context.theme.secondaryTextColor}`,
    },
  };
};

export const timerStyle = (context) => {
  return {
    height: "24px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 0 0 8px",
  };
};

export const sendButtonStyle = (img, context) => {
  const themecolor = getDataFromParams("fontColor") ?? "#ffffff";
  return {
    height: "40px",
    width: "40px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 -1 0 8px",
    borderRadius: "30px",
    backgroundColor: `${themecolor}`,
    i: {
      width: "24px",
      height: "24px",
      display: "inline-block",
      mask: `url(${img}) center center no-repeat`,
      backgroundColor: "#fff",
    },
  };
};

export const reactionBtnStyle = () => {
  return {
    cursor: "pointer",
    height: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 0 0 16px",
  };
};

export const attachstyle = (e) => {
  const themecolor = getDataFromParams("fontColor") ?? "#ffffff";
  if (e == true) {
    return {
      display: "flex",
      backgroundColor: "#fff",
      borderRadius: "50%",
      i: {
        backgroundColor: `${themecolor} !important`,
      },
    };
  } else {
    return {
      display: "flex",
    };
  }
};

export const stickerBtnStyle = (img, context) => {
  return {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 0 0 2px",
    " div > i": {
      width: "22px",
      height: "22px",
      display: "inline-block",
      mask: `url(${img}) center center no-repeat`,
      backgroundColor: "#494F73",
    },
  };
};
