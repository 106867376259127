
import React from "react";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { CometChatContext } from "../../../util/CometChatContext";
import { GroupDetailManager } from "../../Groups/CometChatGroupDetails/controller.js";
import { CometChatAvatar } from "../../Shared";
import { avatarStyle } from "./style";
import { getDataFromParams } from ".../../../src/getUrlParams";
import * as enums from "../../../util/enums.js";

const LoginUserUid = getDataFromParams("Set1");

class CometChatMemberContainer extends React.Component {
  static contextType = CometChatContext;

  constructor(props) {
    super(props);
    this.state = {
      memberlist: [],
      guid: "",
      userRoles: {}, // Add state to keep track of user roles
    };
    this.GroupDetailManager = null;
    this._isMounted = false; // Add a mounted flag
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchGroupMembers(this.context.item.guid);
  }

  componentDidUpdate(prevProps, prevState) {
    const newGuid = this.context.item.guid;

    if (newGuid !== prevState.guid) {
      this.setState({ memberlist: [], guid: newGuid, userRoles: {} }, () => {
        this.fetchGroupMembers(newGuid);
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.GroupDetailManager) {
      this.GroupDetailManager.removeListeners();
    }
  }

  fetchUserDetails = async (uid) => {
    let limit = 1;
    let UIDs = [uid];
    const tagRoleMap = {
      ambassadors: "Ambassador",
      prospect: "Prospect",
      college_admin: "Admin",
    };

    for (const [tag, role] of Object.entries(tagRoleMap)) {
      let usersRequest = new CometChat.UsersRequestBuilder()
        .setLimit(limit)
        .setUIDs(UIDs)
        .setTags([tag])
        .build();
      try {
        let userList = await usersRequest.fetchNext();
        if (userList.length > 0) {
          return role; // Return the role if a user with the tag is found
        }
      } catch (error) {
        console.error(`Error fetching users with tag ${tag}:`, error);
      }
    }

    return "User"; // Return "User" if no specific role is found
  };

  fetchGroupMembers = (guid) => {
    if (this.GroupDetailManager) {
      this.GroupDetailManager.removeListeners(); // Remove listeners for previous group
    }
    this.GroupDetailManager = new GroupDetailManager(guid);

    const administratorslist = [];
    const moderatorslist = [];

    this.GroupDetailManager.fetchNextGroupMembers()
      .then((groupMembers) => {
        const memberPromises = groupMembers.map((member) => {
          if (member.scope === CometChat.GROUP_MEMBER_SCOPE.ADMIN) {
            administratorslist.push(member);
          }

          if (member.scope === CometChat.GROUP_MEMBER_SCOPE.MODERATOR) {
            moderatorslist.push(member);
          }

          return this.fetchUserDetails(member.uid).then((role) => ({
            ...member,
            role,
          }));
        });

        Promise.all(memberPromises).then((updatedMembers) => {
          if (this._isMounted && this.state.guid === guid) {
            const userRoles = updatedMembers.reduce((roles, member) => {
              roles[member.uid] = member.role;
              return roles;
            }, {});

            this.context.setAllGroupMembers(
              updatedMembers,
              administratorslist,
              moderatorslist
            );

            const sortedGroupMembers = updatedMembers.sort((a, b) => {
              if (a.uid === LoginUserUid) return -1; // Place LoginUserUid at the top
              if (b.uid === LoginUserUid) return 1; // Place LoginUserUid at the top
              return a.uid.localeCompare(b.uid); // Sort the rest of the UIDs
            });

            this.setState({ memberlist: sortedGroupMembers, userRoles });
          }
        });
      })
      .catch((error) => {
        // Handle error here
        console.log(error);
      });

    // Attach listeners for real-time updates
    this.attachGroupListeners();
  };

  attachGroupListeners = () => {
    if (this.GroupDetailManager) {
      this.GroupDetailManager.attachListeners(
        (key, message, group, options) => {
          switch (key) {
            case enums.GROUP_MEMBER_ADDED:
              this.handleGroupMemberAdded(message, options.user, group.guid);
              break;
            case enums.GROUP_MEMBER_LEFT:
            case enums.GROUP_MEMBER_KICKED:
            case enums.GROUP_MEMBER_BANNED:
              this.handleGroupMemberRemoved(options.user, group.guid);
              break;
            default:
              break;
          }
        }
      );
    }
  };

  handleGroupMemberAdded = (message, newMember, guid) => {
    if (this.state.guid !== guid) return; // Only update if the group matches the current group

    this.fetchUserDetails(newMember.uid).then((role) => {
      if (this._isMounted) {
        this.setState((prevState) => ({
          memberlist: [...prevState.memberlist, { ...newMember, role }],
          userRoles: { ...prevState.userRoles, [newMember.uid]: role },
        }));
      }
    });
  };

  handleGroupMemberRemoved = (removedMember, guid) => {
    if (this.state.guid !== guid) return; // Only update if the group matches the current group

    if (this._isMounted) {
      this.setState((prevState) => ({
        memberlist: prevState.memberlist.filter(
          (member) => member.uid !== removedMember.uid
        ),
      }));
    }
  };
  truncateName(name) {
    const words = name.split(' ');
    if (words.length > 2) {
      return words.slice(0, 2).join(' ') + '...';
    }
    return name;
  }


  decrypt(message) {
    let shift = 23;
    let decryptedMessage = "";
    let specialValue = "#$^%&*#$";
    if (!message.includes(specialValue)) {
      return message;
    } else {
      message = message.replaceAll("#$^%&*#$", "");
      for (let i = 0; i < message.length; i++) {
        const charCode = message.charCodeAt(i);
        if (charCode >= 65 && charCode <= 90) {
          decryptedMessage += String.fromCharCode(
            ((charCode - 65 + shift) % 26) + 65
          );
        } else if (charCode >= 97 && charCode <= 122) {
          decryptedMessage += String.fromCharCode(
            ((charCode - 97 + shift) % 26) + 97
          );
        } else {
          decryptedMessage += message[i];
        }
      }
      return decryptedMessage;
    }
  }

  decryptedName(name) {
    return this.decrypt(name);
  }

  render() {
    const membersList = this.state.memberlist;

    const onlineCount = membersList.filter(
      (membersList) => membersList.status === "online"
    ).length;

    const groupMembers = membersList.map((member, key) => (
      <div className="memtxtimg" key={key}>
        <div className="memavatar" style={avatarStyle(member.status)}>
          <CometChatAvatar user={member} />
        </div>
        <div>
           <h3 className="member-name">
            <span className="MemberName">
              {this.truncateName(this.decryptedName(member.name))}
            </span>
            {LoginUserUid === member.uid && <span className="you">(You)</span>}
          </h3>
          {LoginUserUid !== member.uid ? (
            this.state.userRoles[member.uid] ? (
              <>
                <h6>{this.state.userRoles[member.uid]}</h6>
                {/* {console.log("userroles", this.state.userRoles[member.uid])} */}
              </>
            ) : (
              <h6>Loading...</h6>
            )
          ) : null}
        </div>
      </div>
    ));
    
    return (
      <div className="memberBox">
        <div className="memberDiv">
          <h4>
            Members (<span className="onlineCount">{onlineCount}</span>/
            {membersList.length})
          </h4>
        </div>
        <div className="groupmemberlist">{groupMembers}</div>
      </div>
    );
  }
}

export { CometChatMemberContainer };
