export const messageContainerStyle = () => {
	return {
		alignSelf: "flex-start",
		marginBottom: "16px",
		paddingLeft: "16px",
		paddingRight: "16px",
		maxWidth: "65%",
		clear: "both",
		position: "relative",
		display: "flex",
		flexDirection: "column",
		flexShrink: "0",
	};
};

export const messageWrapperStyle = () => {
	return {
		width: "auto",
		flex: "1 1",
		alignSelf: "flex-start",
		display: "flex",
	};
};

export const messageThumbnailStyle = () => {
	return {
		width: "36px",
		height: "36px",
		margin: "10px 5px",
		float: "left",
		flexShrink: "0",
	};
};

export const messageDetailStyle = (props,context) => {	

	
	if (context?.type === 'group') {
		return {
		  borderRadius: "25px",
		  border: "1px solid var(--V3-Colors-Line, #CBD6E2)",
		  background: "var(--V3-Colors-BG2, #F5F8FA)",
		  padding: "8px 20px 15px 20px",
		};
	  }
	return {
		flex: "1 1",
		display: "flex",
		flexDirection: "column",
	};
};

export const nameWrapperStyle = (avatar) => {
	const paddingValue = avatar
		? {
				padding: "3px 5px",
		  }
		: {};

	return {
		alignSelf: "flex-start",
		...paddingValue,
	};
};

export const nameStyle = (context,props) => {
	return {
		fontSize: "14px",
		color: `${
			context.type === 'group'
			  ? props?.message?.sender?.role === 'prospect'
				? '#4E156B'
				: props?.message?.sender?.role === 'ambassador'
				? '#9C2B5F'
				: props?.message?.sender?.role === 'staff'
				? '#C66F37'
				: context.theme.color.search
			  : context.theme.color.search
		  }`,
	};
};

export const messageImgContainerStyle = (context) => {
	const borderStyle = context?.type === 'group' ? "none" : "1px solid var(--V3-Colors-Line, #CBD6E2)";
	const padding= context?.type === 'group' ? "0px" : "15px 20px";
	// return {
	// 	width: "auto",
	// 	flex: "1 1",
	// 	alignSelf: "flex-start",
	// 	display: "flex",
	// };
	return {
		width: "auto",
		flex: "1 1 auto",
		alignSelf: "flex-start",
		display: "flex",
		padding: padding,
		flexDirection: "column",
		alignItems: "flex-start",
		gap: "5px",
		borderRadius: "25px",
		border: borderStyle,
		background: "var(--V3-Colors-BG2, #F5F8FA)",
	  };
};

export const messageImgWrapperStyle = (context) => {
	const groupcheck = context?.type === 'group' ? "5px 0px" : "0px";
	const mq = [...context.theme.breakPoints];

	return {
		display: "inline-block",
		alignSelf: "flex-start",
		// maxWidth: "300px",
		maxWidth:"fit-content",
		height: "200px",
		cursor: "pointer",
		padding:groupcheck,
		img: {
			borderRadius: "8px",
			height: "100%",
		},
		[`@media ${mq[1]}, ${mq[2]}`]: {
			minWidth: "50px",
			maxWidth: "150px",
			height: "100px",
			padding: "2px 2px",
		},
	};
};

// export const messageInfoWrapperStyle = (context) => {
// 	return {
// 		alignSelf: "flex-start",
// 		padding: "4px 8px",
// 		display: "flex",
// 		alignItems: "center",
// 		justifyContent: "flex-start",
// 		height: "25px",
// 	};
// };
export const messageInfoWrapperStyle = (context) => {
	const paddingCheck = context?.type === 'group' ? "4px 46px" : "4px 4px";
	return {
		alignSelf: "flex-start",
		padding: paddingCheck,
	alignItems: "center",
	justifyContent: "flex-start",
	height: "25px",
};
};

export const messageReactionsWrapperStyle = () => {
	return {
		display: "flex",
		alignSelf: "flex-start",
		width: "100%",
		flexWrap: "wrap",
		justifyContent: "flex-start",
		minHeight: "36px",
	};
};

export const nameRole = () => {
	return {
	  color: 'var(--V3-Colors-Placeholder, #8F98A3)',
	  fontFamily: '"Lexend Deca", sans-serif',
	  fontSize: '14px',
	  fontStyle: 'normal',
	  fontWeight: 300,
	  lineHeight: '24px',
	textTransform: "capitalize",

	};
  };
  export const circle = () => {
	return {
	  width: '5px',
	  height: '5px',
	  display: 'inline-block',
	  borderRadius: '50%', 
	  backgroundColor: '#CBD6E2', 
	  margin: '1px 5px',
	};
  };