export const msgTimestampStyle = (context, props, loggedInUser) => {
	return {
		display: "flex",
		fontSize: "10px", // Updated font size
		fontWeight: "400", // Updated font weight
		lineHeight: "14px", // Updated line height
		textTransform: "uppercase",
		color: "var(--V3-Colors-TL-Main, #494F73)", // Updated color
		// color: `${context.theme.color.search}`,
		fontFamily: "Lexend Deca", // Updated font family
		fontStyle: "normal", // Updated font style
	};
};

export const iconStyle = (img, color) => {
	return {
		mask: `url(${img}) center center no-repeat`,
		backgroundColor: `${color}`,
		display: "inline-block",
		width: "24px",
		height: "24px",
	};
};
