import React from "react";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import { CometChat } from "@cometchat/chat-sdk-javascript";

import {
  CometChatMessageHeader,
  CometChatMessageList,
  CometChatMessageComposer,
  CometChatLiveReactions,
  CometChatMessageThread,
  CometChatImageViewer,
  CometChatBlockedUser,
} from "../";

import {
  CometChatIncomingCall,
  CometChatOutgoingCall,
  CometChatOutgoingDirectCall,
  CometChatIncomingDirectCall,
} from "../../Calls";

import { CometChatUserDetails } from "../../Users";
import { CometChatGroupDetails } from "../../Groups";
import { CometChatToastNotification } from "../../Shared";

import {
  CometChatContextProvider,
  CometChatContext,
} from "../../../util/CometChatContext";
import * as enums from "../../../util/enums.js";
import { checkMessageForExtensionsData } from "../../../util/common";
import { SoundManager } from "../../../util/SoundManager";
import { CometChatEvent } from "../../../util/CometChatEvent";
import { CometChatMemberContainer } from "../CometChatMemberContainer/index.js";

import { theme } from "../../../resources/theme";
import Translator from "../../../resources/localization/translator";

import {
  chatWrapperStyle,
  chatSecondaryStyle,
  reactionsWrapperStyle,
  messagePaneTopStyle,
  messagePaneBannerStyle,
  messagePaneUnreadBannerStyle,
  messagePaneUnreadBannerMessageStyle,
  iconArrowDownStyle,
  chatContainerStyle,
  messageContainerList,
  groupMemberList,
  groupuserConatinerList,
} from "./style";
import { getDataFromParams } from "../../../../getUrlParams.js";

class CometChatMessages extends React.PureComponent {
  static contextType = CometChatContext;

  constructor(props) {
    super(props);

    CometChat.getLoggedinUser()
      .then((user) => (this.loggedInUser = user))
      .catch((error) => this.errorHandler("SOMETHING_WRONG"));

    this.state = {
      messageList: [],
      BrodCastIs: getDataFromParams("BrodCastIS") === "true",
      scrollToBottom: true,
      messageToBeEdited: "",
      replyPreview: null,
      liveReaction: false,
      messageToReact: null,
      unreadMessages: [],
      viewdetailscreen: false,
      threadmessageview: false,
      threadmessagetype: null,
      threadmessageitem: {},
      threadmessageparent: {},
      viewOriginalImage: false,
      enableGroupActionMessages: false,
      enableCallActionMessages: false,
      enableSendingOneOnOneMessage: false,
      enableSendingGroupMessage: false,
      enableHideDeletedMessages: false,
    };

    this.contextProviderRef = React.createRef();
    this.composerRef = React.createRef();
    this.messageListRef = React.createRef();
    this.outgoingCallRef = React.createRef();
    this.outgoingDirectCallRef = React.createRef();
    this.toastRef = React.createRef();

    this.reactionName = "heart";
  }

  componentDidMount() {
    // console.log("shouldShowInputBoxcometchatmessages",this.props.shouldShowInputBox)
    this.type = this.getContext().type;
    this.item = this.getContext().item;

    this.enableGroupActionMessages();
    this.enableCallActionMessages();
    this.enableSendingOneOnOneMessage();
    this.enableSendingGroupMessage();
    this.enableHideDeletedMessages();
  }

  componentDidUpdate(prevProps, prevState) {
    if (Object.keys(this.item).length) {
      const ifChatWindowChanged = () => {
        let output = false;

        if (
          this.getContext().type === CometChat.ACTION_TYPE.TYPE_USER &&
          (this.getContext().item.uid !== this.item.uid ||
            this.getContext().item.blockedByMe !== this.item.blockedByMe)
        ) {
          output = true;
        } else if (
          this.getContext().type === CometChat.ACTION_TYPE.TYPE_GROUP &&
          this.getContext().item.guid !== this.item.guid
        ) {
          output = true;
        } else if (this.type !== this.getContext().type) {
          output = true;
        }

        return output;
      };

      if (ifChatWindowChanged() === true) {
        this.setState({
          messageList: [],
          scrollToBottom: true,
          messageToBeEdited: "",
          threadmessageview: false,
          viewdetailscreen: false,
          unreadMessages: [],
        });
      }
    }

    this.type = this.getContext().type;
    this.item =
      this.getContext().type === CometChat.ACTION_TYPE.TYPE_USER ||
      CometChat.ACTION_TYPE.TYPE_GROUP
        ? this.getContext().item
        : {};

    this.enableGroupActionMessages();
    this.enableCallActionMessages();
    this.enableSendingOneOnOneMessage();
    this.enableSendingGroupMessage();
    this.enableHideDeletedMessages();

    /**
     * Custom message to be appended or updated for direct calling
     */
    if (Object.keys(this.props.widgetsettings).length) {
      if (
        Object.keys(this.getContext().directCallCustomMessage).length &&
        this.getContext().directCallCustomMessageAction.trim().length &&
        (this.getContext().directCallCustomMessage !==
          this.directCallCustomMessage ||
          this.getContext().directCallCustomMessageAction !==
            this.directCallCustomMessageAction)
      ) {
        const customMessage = this.getContext().directCallCustomMessage;
        const messageAction =
          this.getContext().directCallCustomMessageAction.trim();

        switch (messageAction) {
          case enums.ACTIONS["MESSAGE_COMPOSED"]:
            this.appendMessage(customMessage);
            break;
          case enums.ACTIONS["MESSAGE_SENT"]:
          case enums.ACTIONS["ERROR_IN_SENDING_MESSAGE"]: {
            this.messageSent(customMessage);
            //this.getContext().setLastMessage(customMessage[0]);
            CometChatEvent.triggerHandler("updateLastMessage", {
              ...customMessage[0],
            });
            setTimeout(() => {
              this.getContext().setDirectCallCustomMessage({}, "");
            }, 1000);

            break;
          }
          default:
            break;
        }
      }

      this.directCallCustomMessage = this.getContext().directCallCustomMessage;
      this.directCallCustomMessageAction =
        this.getContext().directCallCustomMessageAction.trim();
    }
  }

  enableGroupActionMessages = () => {
    this.getContext()
      .FeatureRestriction.isGroupActionMessagesEnabled()
      .then((response) => {
        /**
         * Don't update state if the response has the same value
         */
        if (response !== this.state.enableGroupActionMessages) {
          this.setState({ enableGroupActionMessages: response });
        }
      })
      .catch((error) => {
        if (this.state.enableGroupActionMessages !== false) {
          this.setState({ enableGroupActionMessages: false });
        }
      });
  };

  enableCallActionMessages = () => {
    this.getContext()
      .FeatureRestriction.isCallActionMessagesEnabled()
      .then((response) => {
        /**
         * Don't update state if the response has the same value
         */
        if (response !== this.state.enableCallActionMessages) {
          this.setState({ enableCallActionMessages: response });
        }
      })
      .catch((error) => {
        if (this.state.enableCallActionMessages !== false) {
          this.setState({ enableCallActionMessages: false });
        }
      });
  };

  enableSendingOneOnOneMessage = () => {
    this.getContext()
      .FeatureRestriction.isOneOnOneChatEnabled()
      .then((response) => {
        if (response !== this.state.enableSendingOneOnOneMessage) {
          this.setState({ enableSendingOneOnOneMessage: response });
        }
      })
      .catch((error) => {
        if (this.state.enableSendingOneOnOneMessage !== false) {
          this.setState({ enableSendingOneOnOneMessage: false });
        }
      });
  };

  enableSendingGroupMessage = () => {
    this.getContext()
      .FeatureRestriction.isGroupChatEnabled()
      .then((response) => {
        if (response !== this.state.enableSendingGroupMessage) {
          this.setState({ enableSendingGroupMessage: response });
        }
      })
      .catch((error) => {
        if (this.state.enableSendingGroupMessage !== false) {
          this.setState({ enableSendingGroupMessage: false });
        }
      });
  };

  enableHideDeletedMessages = () => {
    this.getContext()
      .FeatureRestriction.isHideDeletedMessagesEnabled()
      .then((response) => {
        if (response !== this.state.enableHideDeletedMessages) {
          this.setState({ enableHideDeletedMessages: response });
        }
      })
      .catch((error) => {
        if (this.state.enableHideDeletedMessages !== false) {
          this.setState({ enableHideDeletedMessages: false });
        }
      });
  };

  getContext = () => {
    if (this.props._parent.length) {
      return this.context;
    } else {
      return this.contextProviderRef.state;
    }
  };

  actionHandler = (action, messages, key, group, options) => {
    switch (action) {
      case enums.ACTIONS["CUSTOM_MESSAGE_RECEIVED"]:
      case enums.ACTIONS["MESSAGE_RECEIVED"]:
        {
          const message = messages[0];
          if (message.parentMessageId) {
            this.updateReplyCount(messages);
          } else {
            this.smartReplyPreview(messages);
            this.appendMessage(messages);
          }

          SoundManager.play(
            enums.CONSTANTS.AUDIO["INCOMING_MESSAGE"],
            this.getContext()
          );
        }
        break;
      case enums.ACTIONS["MESSAGE_READ"]: {
        if (this.props?.actionGenerated) {
          this.props?.actionGenerated(action, messages);
        }

        break;
      }
      case enums.ACTIONS["MESSAGE_COMPOSED"]: {
        this.appendMessage(messages);
        break;
      }
      case enums.ACTIONS["MESSAGE_SENT"]:
        this.messageSent(messages);
        CometChatEvent.triggerHandler("updateLastMessage", { ...messages[0] });
        //this.getContext().setLastMessage(messages[0]);
        break;
      case enums.ACTIONS["GUIDCHANGE"]:
        this.getContext().item.guid
        break;
      case enums.ACTIONS["ERROR_IN_SENDING_MESSAGE"]:
        this.messageSent(messages);
        break;
      case enums.ACTIONS["ON_MESSAGE_READ_DELIVERED"]:
        this.updateMessages(messages);
        break;
      case enums.ACTIONS["ON_MESSAGE_EDITED"]: {
        this.updateMessages(messages);
        //update the parent message of thread message
        this.updateParentThreadedMessage(key, "edit");
        break;
      }
      case enums.ACTIONS["ON_MESSAGE_DELETED"]: {
        this.removeMessages(messages);
        //remove the thread message
        this.updateParentThreadedMessage(messages[0], "delete");
        break;
      }
      case enums.ACTIONS["MESSAGES_FETCHED"]:
        this.prependMessages(messages);
        break;
      case enums.ACTIONS["MESSAGES_INITIAL_FETCH"]:
        this.prependMessagesAndScrollToBottom(messages);
        break;
      case enums.ACTIONS["REFRESHING_MESSAGES"]:
        this.refreshingMessages();
        break;
      case enums.ACTIONS["MESSAGES_REFRESHED"]:
        this.messageRefreshed(messages);
        break;
      case enums.ACTIONS["NEW_MESSAGES"]:
        this.newMessagesArrived(messages);
        break;
      case enums.ACTIONS["CLEAR_UNREAD_MESSAGES"]:
        this.jumpToMessages(true);
        break;
      case enums.ACTIONS["DELETE_MESSAGE"]:
        this.deleteMessage(messages);
        break;
      case enums.ACTIONS["EDIT_MESSAGE"]:
        this.editMessage(messages);
        break;
      case enums.ACTIONS["MESSAGE_EDITED"]:
        this.messageEdited(messages);
        break;
      case enums.ACTIONS["CLEAR_EDIT_PREVIEW"]:
        this.clearEditPreview();
        break;
      case enums.GROUP_MEMBER_SCOPE_CHANGED:
      case enums.GROUP_MEMBER_KICKED:
      case enums.GROUP_MEMBER_BANNED:
      case enums.GROUP_MEMBER_UNBANNED:
      case enums.GROUP_MEMBER_ADDED:
      case enums.GROUP_MEMBER_LEFT:
      case enums.GROUP_MEMBER_JOINED:
        this.groupUpdated(action, messages, group, options);
        break;
      case enums.INCOMING_CALL_RECEIVED:
      case enums.INCOMING_CALL_CANCELLED:
      case enums.OUTGOING_CALL_ACCEPTED:
      case enums.OUTGOING_CALL_REJECTED:
        this.appendCallMessage(messages);
        break;
      case enums.ACTIONS["VIEW_ORIGINAL_IMAGE"]:
        this.toggleOriginalImageView(messages);
        break;
      case enums.ACTIONS["INITIATE_AUDIO_CALL"]:
        this.audioCall();
        break;
      case enums.ACTIONS["INITIATE_VIDEO_CALL"]:
        this.videoCall();
        break;
      case enums.ACTIONS["VIEW_DETAIL"]:
      case enums.ACTIONS["CLOSE_GROUP_DETAIL"]:
      case enums.ACTIONS["CLOSE_USER_DETAIL"]:
        this.toggleDetailView();
        break;
      case enums.ACTIONS["TOGGLE_SIDEBAR"]:
        this.toggleDetailView();
        this.props.actionGenerated(action);
        break;
      case enums.ACTIONS["SEND_LIVE_REACTION"]:
        this.toggleReaction(true);
        break;
      case enums.ACTIONS["STOP_LIVE_REACTION"]:
        this.toggleReaction(false);
        break;
      case enums.TRANSIENT_MESSAGE_RECEIVED:
        this.liveReactionReceived(messages);
        break;
      case enums.ACTIONS["REACT_TO_MESSAGE"]:
        this.reactToMessage(messages);
        break;
      case enums.ACTIONS["OUTGOING_CALL_ACCEPTED"]:
      case enums.ACTIONS["USER_JOINED_CALL"]:
      case enums.ACTIONS["USER_LEFT_CALL"]:
      case enums.ACTIONS["OUTGOING_CALL_ENDED"]:
      case enums.ACTIONS["OUTGOING_CALL_REJECTED"]:
      case enums.ACTIONS["OUTGOING_CALL_CANCELLED"]:
      case enums.ACTIONS["INCOMING_CALL_ACCEPTED"]:
      case enums.ACTIONS["INCOMING_CALL_ENDED"]:
      case enums.ACTIONS["INCOMING_CALL_REJECTED"]:
      case enums.ACTIONS["DIRECT_CALL_ENDED"]:
      case enums.ACTIONS["DIRECT_CALL_ERROR"]:
        break;
      case enums.ACTIONS["JOIN_DIRECT_CALL"]: {
        //if used in a chat widget, trigger the event to the app component as directcall component is included outside of iframe
        if (Object.keys(this.props.widgetsettings).length) {
          this.props.actionGenerated(action, messages);
        } else {
          const sessionID =
            this.getContext().type === CometChat.ACTION_TYPE.TYPE_GROUP
              ? this.getContext().item.guid
              : null;
          this.outgoingDirectCallRef.joinCall(sessionID);
        }

        break;
      }
      case enums.ACTIONS["VIEW_THREADED_MESSAGE"]:
        this.viewThreadedMessage(messages);
        break;
      case enums.ACTIONS["THREAD_MESSAGE_COMPOSED"]:
        this.threadMessageComposed(messages);
        break;
      case enums.ACTIONS["CLOSE_THREADED_MESSAGE"]:
        this.closeThreadedMessage();
        break;
      case enums.ACTIONS["ADD_GROUP_MEMBER_SUCCESS"]:
        this.appendMemberAddedMessage(messages);
        break;
      case enums.ACTIONS["UNBAN_GROUP_MEMBER_SUCCESS"]:
        this.appendMemberUnbannedMessage(messages);
        break;
      case enums.ACTIONS["SCOPECHANGE_GROUPMEMBER_SUCCESS"]:
        this.appendMemberScopeChangedMessage(messages);
        break;
      case enums.ACTIONS["KICK_GROUPMEMBER_SUCCESS"]:
        this.appendMemberKickedMessage(messages);
        break;
      case enums.ACTIONS["BAN_GROUPMEMBER_SUCCESS"]:
        this.appendMemberBannedMessage(messages);
        break;
      case enums.ACTIONS["ERROR"]:
        this.errorHandler(key);
        break;
      case enums.ACTIONS["INFO"]:
        this.infoMessageHandler(key);
        break;
      default:
        break;
    }
  };

  errorHandler = (errorCode) => {
    if (typeof this.toastRef.setError === "function") {
      this.toastRef?.setError(errorCode);
    }
  };

  infoMessageHandler = (infoCode) => {
    if (typeof this.toastRef.setInfo === "function") {
      this.toastRef?.setInfo(infoCode);
    }
  };

  appendMemberAddedMessage = (messages) => {
    //if group action messages are disabled
    if (this.state.enableGroupActionMessages === false) {
      return false;
    }

    const messageList = [];
    messages.forEach((eachMember) => {
      const sentAt = (new Date() / 1000) | 0;
      const messageObj = {
        receiver: { ...this.context.item },
        receiverId: this.context.item.guid,
        receiverType: CometChat.RECEIVER_TYPE.GROUP,
        sender: { ...this.loggedInUser },
        category: CometChat.CATEGORY_ACTION,
        type: CometChat.ACTION_TYPE.TYPE_GROUP_MEMBER,
        sentAt: sentAt,
        action: CometChat.ACTION_TYPE.MEMBER_ADDED,
        actionBy: { ...this.loggedInUser },
        actionOn: { ...eachMember },
        actionFor: { ...this.context.item },
      };

      messageList.push(messageObj);
    });

    this.appendMessage(messageList);
  };

  appendMemberUnbannedMessage = (messages) => {
    //if group action messages are disabled
    if (this.state.enableGroupActionMessages === false) {
      return false;
    }

    const messageList = [];
    messages.forEach((eachMember) => {
      const sentAt = (new Date() / 1000) | 0;
      const messageObj = {
        receiver: { ...this.context.item },
        receiverId: this.context.item.guid,
        receiverType: CometChat.RECEIVER_TYPE.GROUP,
        sender: { ...this.loggedInUser },
        category: CometChat.CATEGORY_ACTION,
        type: CometChat.ACTION_TYPE.TYPE_GROUP_MEMBER,
        sentAt: sentAt,
        action: CometChat.ACTION_TYPE.MEMBER_UNBANNED,
        actionBy: { ...this.loggedInUser },
        actionOn: { ...eachMember },
      };

      messageList.push(messageObj);
    });

    this.appendMessage(messageList);
  };

  appendMemberScopeChangedMessage = (messages) => {
    //if group action messages are disabled
    if (this.state.enableGroupActionMessages === false) {
      return false;
    }

    const messageList = [];
    messages.forEach((eachMember) => {
      const newScope = Translator.translate(eachMember.scope, this.props.lang);

      const sentAt = (new Date() / 1000) | 0;
      const messageObj = {
        receiver: { ...this.context.item },
        receiverId: this.context.item.guid,
        receiverType: CometChat.RECEIVER_TYPE.GROUP,
        sender: { ...this.loggedInUser },
        category: CometChat.CATEGORY_ACTION,
        type: CometChat.ACTION_TYPE.TYPE_GROUP_MEMBER,
        sentAt: sentAt,
        action: CometChat.ACTION_TYPE.MEMBER_SCOPE_CHANGED,
        actionBy: { ...this.loggedInUser },
        actionOn: { ...eachMember },
        newScope: newScope,
      };
      messageList.push(messageObj);
    });

    this.appendMessage(messageList);
  };

  appendMemberKickedMessage = (messages) => {
    //if group action messages are disabled
    if (this.state.enableGroupActionMessages === false) {
      return false;
    }

    const messageList = [];
    messages.forEach((eachMember) => {
      const sentAt = (new Date() / 1000) | 0;
      const messageObj = {
        receiver: { ...this.context.item },
        receiverId: this.context.item.guid,
        receiverType: CometChat.RECEIVER_TYPE.GROUP,
        sender: { ...this.loggedInUser },
        category: CometChat.CATEGORY_ACTION,
        type: CometChat.ACTION_TYPE.TYPE_GROUP_MEMBER,
        sentAt: sentAt,
        action: CometChat.ACTION_TYPE.MEMBER_KICKED,
        actionBy: { ...this.loggedInUser },
        actionOn: { ...eachMember },
        actionFor: { ...this.context.item },
      };
      messageList.push(messageObj);
    });

    this.appendMessage(messageList);
  };

  appendMemberBannedMessage = (messages) => {
    //if group action messages are disabled
    if (this.state.enableGroupActionMessages === false) {
      return false;
    }

    const messageList = [];
    messages.forEach((eachMember) => {
      const sentAt = (new Date() / 1000) | 0;
      const messageObj = {
        receiver: { ...this.context.item },
        receiverId: this.context.item.guid,
        receiverType: CometChat.RECEIVER_TYPE.GROUP,
        sender: { ...this.loggedInUser },
        category: CometChat.CATEGORY_ACTION,
        type: CometChat.ACTION_TYPE.TYPE_GROUP_MEMBER,
        sentAt: sentAt,
        action: CometChat.ACTION_TYPE.MEMBER_BANNED,
        actionBy: { ...this.loggedInUser },
        actionOn: { ...eachMember },
        actionFor: { ...this.context.item },
      };
      messageList.push(messageObj);
    });

    this.appendMessage(messageList);
  };

  toggleOriginalImageView = (message) => {
    this.setState({ viewOriginalImage: message });
  };

  toggleDetailView = () => {
    let viewdetail = !this.state.viewdetailscreen;
    this.setState({ viewdetailscreen: viewdetail, threadmessageview: false });
  };

  viewThreadedMessage = (parentMessage) => {
    const message = { ...parentMessage };
    const threaditem = { ...this.getContext().item };
    this.setState({
      threadmessageview: true,
      threadmessageparent: message,
      threadmessageitem: threaditem,
      threadmessagetype: this.getContext().type,
      viewdetailscreen: false,
    });
  };

  threadMessageComposed = (messages) => {
    if (this.getContext().type !== this.state.threadmessagetype) {
      return false;
    }

    if (
      (this.state.threadmessagetype === CometChat.ACTION_TYPE.TYPE_GROUP &&
        this.getContext().item.guid !== this.state.threadmessageitem.guid) ||
      (this.state.threadmessagetype === CometChat.ACTION_TYPE.TYPE_USER &&
        this.getContext().item.uid !== this.state.threadmessageitem.uid)
    ) {
      return false;
    }

    this.updateReplyCount(messages);
  };

  closeThreadedMessage = () => {
    this.setState({ threadmessageview: false, viewdetailscreen: false });
  };

  /*
	Updating parent message of threaded conversation, when the message is edited or deleted
	*/
  updateParentThreadedMessage = (message, action) => {
    if (
      this.state.threadmessageview === false ||
      message.id !== this.state.threadmessageparent.id
    ) {
      return false;
    }

    if (action === "delete") {
      this.setState({
        threadmessageparent: { ...message },
        threadmessageview: false,
      });
    } else {
      this.setState({ threadmessageparent: { ...message } });
    }
  };

  getReceiverDetails = () => {
    let receiverId;
    let receiverType;

    if (this.getContext().type === CometChat.ACTION_TYPE.TYPE_USER) {
      receiverId = this.getContext().item.uid;
      receiverType = CometChat.RECEIVER_TYPE.USER;
    } else if (this.getContext().type === CometChat.ACTION_TYPE.TYPE_GROUP) {
      receiverId = this.getContext().item.guid;
      receiverType = CometChat.RECEIVER_TYPE.GROUP;
    }

    return { receiverId: receiverId, receiverType: receiverType };
  };

  audioCall = () => {
    const { receiverId, receiverType } = this.getReceiverDetails();
    const call = new CometChat.Call(
      receiverId,
      CometChat.CALL_TYPE.AUDIO,
      receiverType
    );
    CometChat.initiateCall(call)
      .then((outgoingCall) => {
        //when this component is part of chat widget trigger an event.. (outgoingcall component is used separately in chat widget)
        if (Object.keys(this.props.widgetsettings).length) {
          this.props.actionGenerated(
            enums.ACTIONS["START_AUDIO_CALL"],
            outgoingCall
          );
        } else {
          this.outgoingCallRef.startCall(outgoingCall);
          this.appendCallMessage(outgoingCall);
        }
      })
      .catch((error) => this.errorHandler("SOMETHING_WRONG"));
  };

  videoCall = () => {
    /*
		Direct calling for groups
		*/
    if (this.getContext().type === CometChat.RECEIVER_TYPE.GROUP) {
      const sessionID =
        this.getContext().type === CometChat.ACTION_TYPE.TYPE_GROUP
          ? this.getContext().item.guid
          : null;
      if (Object.keys(this.props.widgetsettings).length) {
        this.props.actionGenerated(
          enums.ACTIONS["START_DIRECT_CALL"],
          sessionID
        );
      } else {
        this.outgoingDirectCallRef.startCall(sessionID);
      }
      return;
    }

    /*
		Default calling for one-on-one
		*/
    const { receiverId, receiverType } = this.getReceiverDetails();
    const call = new CometChat.Call(
      receiverId,
      CometChat.CALL_TYPE.VIDEO,
      receiverType
    );
    CometChat.initiateCall(call)
      .then((outgoingCall) => {
        //when this component is part of chat widget trigger an event.. (outgoingcall component is used separately in chat widget)
        if (Object.keys(this.props.widgetsettings).length) {
          this.props.actionGenerated(
            enums.ACTIONS["START_VIDEO_CALL"],
            outgoingCall
          );
        } else {
          this.outgoingCallRef.startCall(outgoingCall);
        }
      })
      .catch((error) => this.errorHandler("SOMETHING_WRONG"));
  };

  toggleReaction = (flag) => {
    this.setState({ liveReaction: flag });
  };

  liveReactionReceived = (reaction) => {
    const stopReaction = () => {
      this.toggleReaction(false);
    };

    if (reaction.data.type === enums.CONSTANTS["METADATA_TYPE_LIVEREACTION"]) {
      this.reactionName = reaction.data.reaction;
      this.toggleReaction(true);

      const liveReactionInterval = enums.CONSTANTS["LIVE_REACTION_INTERVAL"];
      setTimeout(stopReaction, liveReactionInterval);
    }
  };

  deleteMessage = (message) => {
    const messageId = message.id;
    CometChat.deleteMessage(messageId)
      .then((deletedMessage) => {
        //remove edit preview when message is deleted
        if (deletedMessage.id === this.state.messageToBeEdited.id) {
          this.setState({ messageToBeEdited: "" });
        }

        const messageList = [...this.state.messageList];
        let messageKey = messageList.findIndex((m) => m.id === message.id);

        if (messageList.length - messageKey === 1 && !message.replyCount) {
          CometChatEvent.triggerHandler("updateLastMessage", {
            ...deletedMessage,
          });
          //this.getContext().setLastMessage(deletedMessage);
        }

        this.removeMessages([deletedMessage]);
        this.updateParentThreadedMessage(deletedMessage, "delete");
      })
      .catch((error) => this.errorHandler("SOMETHING_WRONG"));
  };

  editMessage = (message) => {
    this.setState({ messageToBeEdited: message, replyPreview: null });
  };

  messageEdited = (message) => {
    const messageList = [...this.state.messageList];
    let messageKey = messageList.findIndex((m) => m.id === message.id);
    if (messageKey > -1) {
      const messageObj = messageList[messageKey];

      const newMessageObj = Object.assign({}, messageObj, message);

      messageList.splice(messageKey, 1, newMessageObj);
      this.updateMessages(messageList);

      this.updateParentThreadedMessage(newMessageObj, "edit");

      if (messageList.length - messageKey === 1 && !message.replyCount) {
        CometChatEvent.triggerHandler("updateLastMessage", {
          ...newMessageObj,
        });
        //this.getContext().setLastMessage(newMessageObj);
      }
    }
  };

  messageSent = (messages) => {
    const message = messages[0];
    const messageList = [...this.state.messageList];

    let messageKey = messageList.findIndex((m) => m._id === message._id);
    if (messageKey > -1) {
      const newMessageObj = { ...message };

      messageList.splice(messageKey, 1, newMessageObj);
      messageList.sort((a, b) => a.id - b.id);
      this.setState({ messageList: messageList, scrollToBottom: true });
    }
  };

  refreshingMessages = () => {
    this.setState({
      messageList: [],
      messageToBeEdited: "",
      replyPreview: null,
      liveReaction: false,
      messageToReact: null,
    });
    CometChatEvent.triggerHandler(enums.EVENTS["CLEAR_UNREAD_MESSAGES"], {});
  };

  messageRefreshed = (messages) => {
    const messageList = [...messages];
    this.setState({ messageList: messageList, scrollToBottom: true });
  };

  newMessagesArrived = (newMessage) => {
    let unreadMessages = [...this.state.unreadMessages];
    unreadMessages.push(newMessage[0]);

    this.setState({ unreadMessages: unreadMessages });

    CometChatEvent.triggerHandler(enums.EVENTS["NEW_MESSAGES"], {
      unreadMessages: unreadMessages,
    });
  };

  markMessagesAsRead = (scrollToBottom) => {
    if (this.state.unreadMessages.length === 0) {
      return false;
    }

    let unreadMessages = [...this.state.unreadMessages];
    let messageList = [...this.state.messageList];

    unreadMessages.forEach((unreadMessage) => {
      if (unreadMessage.receiverType === CometChat.RECEIVER_TYPE.USER) {
        if (this.messageListRef) {
          messageList.push(unreadMessage);
          this.messageListRef.markMessageAsRead(
            unreadMessage,
            CometChat.ACTION_TYPE.TYPE_USER
          );
        }
      } else if (unreadMessage.receiverType === CometChat.RECEIVER_TYPE.GROUP) {
        if (this.messageListRef) {
          messageList.push(unreadMessage);
          this.messageListRef.markMessageAsRead(
            unreadMessage,
            CometChat.ACTION_TYPE.TYPE_GROUP
          );
        }
      }
    });

    this.setState({
      messageList: messageList,
      scrollToBottom: scrollToBottom,
      unreadMessages: [],
    });
  };

  jumpToMessages = () => {
    if (this.state.unreadMessages.length === 0) {
      return false;
    }

    let unreadMessages = [...this.state.unreadMessages];
    let messageList = [...this.state.messageList];
    messageList = messageList.concat(unreadMessages);

    CometChatEvent.triggerHandler(enums.EVENTS["CLEAR_UNREAD_MESSAGES"], {});

    if (messageList.length > enums.CONSTANTS["MAX_MESSAGE_COUNT"]) {
      if (this.messageListRef) {
        this.messageListRef.reInitializeMessageBuilder();
      }
    } else {
      this.markMessagesAsRead(true);
    }
  };

  //messages are deleted
  removeMessages = (messages) => {
    const deletedMessage = messages[0];
    const messagelist = [...this.state.messageList];

    let messageKey = messagelist.findIndex(
      (message) => message.id === deletedMessage.id
    );
    if (messageKey > -1) {
      if (this.state.enableHideDeletedMessages) {
        messagelist.splice(messageKey, 1);
      } else {
        let messageObj = { ...messagelist[messageKey] };
        let newMessageObj = Object.assign({}, messageObj, deletedMessage);

        messagelist.splice(messageKey, 1, newMessageObj);
      }

      this.setState({ messageList: messagelist, scrollToBottom: false });
    }
  };

  //messages are fetched, scroll to bottom
  prependMessagesAndScrollToBottom = (messages) => {
    const messageList = [...messages, ...this.state.messageList];
    this.setState({ messageList: messageList, scrollToBottom: true });
  };

  //messages are fetched from backend
  prependMessages = (messages) => {
    const messageList = [...messages, ...this.state.messageList];
    this.setState({ messageList: messageList, scrollToBottom: false });
  };

  //message is received or composed & sent
  appendMessage = (message) => {
    let messages = [...this.state.messageList, ...message];
    this.setState({ messageList: messages, scrollToBottom: true });
  };

  //message status is updated
  updateMessages = (messages) => {
    this.setState({ messageList: messages, scrollToBottom: false });
  };

  groupUpdated = (action, message, group, options) => {
    //if group action messages are disabled
    if (this.state.enableGroupActionMessages === true) {
      this.appendMessage([message]);
    }

    this.props.actionGenerated(action, message, group, options);
  };

  appendCallMessage = (message) => {
    //if call action messages are disabled
    if (this.state.enableCallActionMessages === false) {
      return false;
    }

    this.appendMessage([message]);
  };

  updateReplyCount = (messages) => {
    const receivedMessage = messages[0];

    let messageList = [...this.state.messageList];
    let messageKey = messageList.findIndex(
      (m) => m.id === receivedMessage.parentMessageId
    );
    if (messageKey > -1) {
      const messageObj = messageList[messageKey];
      let replyCount = messageObj.hasOwnProperty("replyCount")
        ? messageObj.replyCount
        : 0;
      replyCount = replyCount + 1;
      const newMessageObj = Object.assign({}, messageObj, {
        replyCount: replyCount,
      });

      messageList.splice(messageKey, 1, newMessageObj);
      this.setState({ messageList: messageList, scrollToBottom: false });
    }
  };

  smartReplyPreview = (messages) => {
    const message = messages[0];
    if (
      message.sender.uid === this.loggedInUser.uid ||
      message.category === CometChat.CATEGORY_CUSTOM
    ) {
      return false;
    }

    /**
     * If smart-replies feature is enabled
     */
    this.getContext()
      .FeatureRestriction.isSmartRepliesEnabled()
      .then((response) => {
        if (response === true) {
          const smartReplyData = checkMessageForExtensionsData(
            message,
            "smart-reply"
          );
          if (
            smartReplyData &&
            smartReplyData.hasOwnProperty("error") === false
          ) {
            this.setState({ replyPreview: message });
          } else {
            this.setState({ replyPreview: null });
          }
        }
      });
  };

  clearEditPreview = () => {
    this.setState({ messageToBeEdited: "" });
  };

  reactToMessage = (message) => {
    this.setState({ messageToReact: message });

    if (this.composerRef) {
      this.composerRef.toggleEmojiPicker();
    }
  };

  render() {
    const { BrodCastIs } = this.state;

    /**
     * If used as standalone component
     */
    if (
      this.props._parent.trim().length === 0 &&
      this.props.chatWithUser.trim().length === 0 &&
      this.props.chatWithGroup.trim().length === 0
    ) {
      return (
        <CometChatContextProvider
          ref={(el) => (this.contextProviderRef = el)}
          _component={enums.CONSTANTS["MESSAGES_COMPONENT"]}
          user={this.props.chatWithUser}
          group={this.props.chatWithGroup}
        >
          <div></div>
        </CometChatContextProvider>
      );
    } else if (
      this.props._parent.trim().length &&
      Object.keys(this.getContext().item).length === 0
    ) {
      return (
        <div className="blank__chatbox">
          <div className="svg__image" style={{ 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center'}}>
            <svg
              width="278"
              height="196"
              viewBox="0 0 278 196"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.0234 71.8364C14.2264 72.1993 14.4293 72.5559 14.6384 72.9003"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M15.9297 74.9421C39.0708 109.368 82.8132 63.7129 99.0605 78.429"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M99.9062 79.2776C100.177 79.5728 100.435 79.8926 100.681 80.2308"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M151.102 98.2246C151.507 98.3169 151.907 98.4091 152.301 98.489"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M154.695 98.9258C195.221 105.094 175.524 39.5572 200.818 20.1121"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M201.789 19.3986C202.121 19.1649 202.466 18.9435 202.816 18.7283"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M241.112 189.461C261.005 189.461 277.131 187.591 277.131 185.285C277.131 182.979 261.005 181.109 241.112 181.109C221.22 181.109 205.094 182.979 205.094 185.285C205.094 187.591 221.22 189.461 241.112 189.461Z"
                fill="#DFDFEB"
              />
              <path
                d="M127.839 195.623C178.815 195.623 220.139 190.994 220.139 185.285C220.139 179.576 178.815 174.948 127.839 174.948C76.8631 174.948 35.5391 179.576 35.5391 185.285C35.5391 190.994 76.8631 195.623 127.839 195.623Z"
                fill="#DFDFEB"
              />
              <path
                d="M188.343 146.973V135.399H180.742L182.71 146.973H188.343Z"
                fill="#2C3147"
              />
              <path
                d="M194.288 161.972C189.811 168.14 184.191 172.623 178.072 175.981C173.269 178.606 168.177 180.537 163.079 182.007C156.197 183.994 147.631 185.39 138.425 185.961C113.494 187.511 83.8469 183.01 70.0901 167.765C55.2018 151.302 50.2083 129.711 63.4239 98.0954C67.7102 87.8378 73.9213 76.5348 82.3217 63.9895C95.4943 44.3475 123.475 31.3042 146.444 44.655C159.561 52.2744 164.173 62.4521 163.823 74.3701C163.46 86.2881 169.401 95.4818 177.18 101.324C177.61 101.644 178.041 101.964 178.478 102.271C185.937 107.67 194.245 112.166 198.919 121.113C205.388 133.542 202.252 150.995 194.276 161.972H194.288Z"
                fill="#FECD29"
              />
              <path
                d="M70.0979 167.771C55.2097 151.308 50.2161 129.717 63.4317 98.1015C67.718 87.8439 73.9292 76.5409 82.3296 63.9956"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M163.836 74.3765C163.473 86.2945 169.414 95.4882 177.193 101.33C177.624 101.65 178.054 101.97 178.491 102.277"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M225.069 163.171C226.238 163.946 228.648 168.355 232.455 170.243C235.161 171.578 241.686 171.147 250.123 172.377C250.775 172.469 251.476 172.586 251.999 172.986C252.983 173.755 252.989 175.317 252.318 176.368C251.654 177.42 250.492 178.041 249.373 178.564C241.649 182.223 233.537 182.143 231.883 182.739C229.645 183.557 228.273 185.433 225.9 185.273C223.833 185.138 222.499 183.059 222.031 181.042C221.521 178.828 221.472 178.023 220.894 176.159C217.044 172.137 215.414 169.167 215.685 165.33C218.981 163.848 220.672 164.942 225.063 163.171H225.069Z"
                fill="#E57873"
              />
              <path
                d="M144.063 107.388C159.947 108.286 190.88 111.391 195.658 113.451C202.503 116.385 221.278 145.921 232.661 164.973C228.356 168.829 223.608 172.199 218.959 174.825C209.815 162.04 200.006 149.734 190.345 138.941C172.683 135.307 154.696 133.278 136.665 132.89C135.761 124.656 136.413 114.435 144.063 107.394V107.388Z"
                fill="#494F73"
              />
              <path
                d="M127.629 18.3653C123.644 22.3502 122.894 31.8576 123.14 36.9064C123.392 41.9553 123.466 47.49 120.36 51.4688C118.355 54.0455 115.287 55.5583 112.956 57.8398C108.251 62.4582 107.108 70.053 109.561 76.1657C112.009 82.2785 117.636 86.8231 123.939 88.7356C130.243 90.6358 137.124 90.0762 143.366 87.9792C149.577 85.8883 155.807 81.5282 156.877 75.0711C158.033 68.1036 152.818 61.5173 152.898 54.4575C152.947 50.2635 154.853 46.3523 156.385 42.4534C157.916 38.5545 159.915 26.1507 158.187 24.7486C154.159 21.477 150.758 22.504 145.666 21.477C140.586 20.4623 137.124 16.8156 127.629 18.3714V18.3653Z"
                fill="black"
              />
              <path
                d="M232.521 163.171C234.84 164.973 237.152 166.768 239.513 168.404C247.686 165.969 247.114 166.553 255.287 164.106C255.927 163.915 256.597 163.724 257.243 163.872C258.454 164.149 259.118 165.569 258.959 166.805C258.793 168.048 258.012 169.087 257.218 170.04C251.757 176.62 244.359 179.966 243.123 181.208C241.438 182.893 240.989 185.168 238.763 186.029C236.832 186.779 234.588 185.562 233.474 183.828C232.361 182.081 232.103 179.947 232.005 178.49C229.502 176.116 226.728 174.013 224.539 172.119C226.913 169.364 229.287 166.615 232.521 163.165V163.171Z"
                fill="#FDA68A"
              />
              <path
                d="M111.832 101.699C109.508 107.664 105.941 111.754 105.246 123.586C104.539 135.467 112.632 144.513 126.462 146.217C142.427 148.191 159.59 144.648 175.223 144.445C177.855 144.409 180.425 145.22 182.596 146.721C199.225 158.227 210.503 168.109 227.396 178.459C229.05 173.798 230.373 169.481 235.686 164.493C219.223 147.299 206.875 138.314 188.85 123.586C186.458 121.63 183.531 120.4 180.438 120.148C168.63 119.164 156.19 120.259 145.096 117.848C145.895 114.195 147.377 105.912 146.965 102.868C134.893 101.269 123.295 98.3908 111.826 101.705L111.832 101.699Z"
                fill="#494F73"
              />
              <path
                d="M137.795 66.068C138.207 66.5415 138.693 67.0581 139.364 67.1073C140.077 67.1565 140.661 66.6522 141.122 66.1664C143.484 63.6696 145.04 60.601 145.667 57.4523C145.083 57.1141 144.523 56.8005 144.007 56.5053C143.533 56.2224 143.183 55.8227 142.973 55.3676C142.838 55.0294 142.783 54.6727 142.82 54.2976C143.158 51.1736 143.447 49.4394 144.542 46.2723C139.333 45.1039 134.425 43.6956 131.597 41.3464C131.252 42.8039 130.846 45.645 129.979 48.4616C129.057 51.4503 127.612 54.4021 125.164 55.6689C129.979 58.4916 134.266 62.0215 137.795 66.0618V66.068Z"
                fill="#FDA68A"
              />
              <path
                d="M113.931 61.6588C110.77 65.8036 108.944 96.8163 110.487 105.309C120.917 110.167 136.021 109.54 147.539 107.117C147.244 101.152 149.175 90.9188 150.774 86.614C153.203 78.6318 154.832 75.0404 156.813 64.1494C153.246 61.7326 148.959 59.3281 145.663 57.4524C145.036 60.6133 143.474 63.6759 141.119 66.1665C140.664 66.6461 140.067 67.1566 139.36 67.1074C138.69 67.0643 138.204 66.5416 137.792 66.0681C134.256 62.0216 129.976 58.4979 125.167 55.6567C124.976 55.749 124.798 55.8658 124.601 55.9396C117.523 58.6701 115.628 59.4449 113.931 61.6649V61.6588Z"
                fill="white"
              />
              <path
                d="M142.824 54.2976C142.787 54.6666 142.83 55.0294 142.977 55.3677C133.882 54.685 133.366 49.6792 130.875 44.9133C131.742 42.0967 131.269 42.7978 131.613 41.3403C134.429 43.7018 139.343 45.0916 144.558 46.2662C143.457 49.4333 143.156 51.1736 142.83 54.2976H142.824Z"
                fill="black"
              />
              <path
                d="M156.805 64.1804C159.481 66.5665 160.12 97.124 162.186 98.9689C163.287 99.9529 176.232 106.545 183.403 110.057C180.931 111.625 178.717 113.605 176.878 115.911C168.619 114.552 156.24 111.096 153.251 108.87C149.524 106.09 149.457 88.8405 148.43 78.5706C150.797 73.9706 151.707 68.8234 156.805 64.1804Z"
                fill="#77136D"
              />
              <path
                d="M155.769 114.195C153.008 115.321 152.824 115.438 150.29 113.845C148.015 112.418 146.244 110.868 146.244 106.17C146.244 90.4577 148.833 71.6952 144.227 56.6409C148.402 58.9654 152.319 60.6381 155.979 63.3378C157.079 64.1557 157.374 65.5271 157.374 66.9046C157.381 93.2804 156.686 97.7143 155.763 114.195H155.769Z"
                fill="#77136D"
              />
              <path
                d="M156.461 67.4089C155.618 80.194 155.815 92.8008 154.93 106.164"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M155.922 84.794C157.176 83.5272 158.117 81.9467 158.609 80.231"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M140.623 118.057L140.586 120.074L168.081 120.683L204.813 117.092L204.942 114.294L140.623 118.057Z"
                fill="#333333"
              />
              <path
                d="M140.586 118.051L192.606 111.858L198.27 115.081L167.909 118.309L140.586 118.051Z"
                fill="#232323"
              />
              <path
                d="M229.21 82.5125L227.961 80.9812L187.386 82.5125L166.797 118.316L167.873 119.361L229.21 82.5125Z"
                fill="#333333"
              />
              <path
                d="M188.181 83.8285L167.875 119.361L206.648 115.647L229.211 82.5125L188.181 83.8285Z"
                fill="#232323"
              />
              <path
                d="M152.59 35.9043C155.185 34.5944 158.124 35.1848 159.158 37.2326C160.191 39.2804 158.93 41.9986 156.335 43.3084C153.74 44.6183 150.8 44.0279 149.767 41.9801C148.734 39.9323 149.995 37.2141 152.59 35.9043Z"
                fill="#E57873"
              />
              <path
                d="M158.665 29.9083C158.185 33.3152 156.66 37.0788 154.434 41.2421C153.659 37.8414 153.161 33.6903 152.46 29.5516C151.765 25.3945 150.867 21.2435 149.317 17.8489C148.967 17.0986 148.592 16.3791 148.18 15.715C149.102 15.6289 150.025 15.715 150.91 15.9548C152.294 16.3238 153.585 17.0371 154.649 17.9965C156.408 19.5646 157.582 21.6986 158.29 23.9493C158.874 25.8004 158.978 27.7867 158.659 29.9145L158.665 29.9083Z"
                fill="black"
              />
              <path
                d="M156.059 33.4319C156.035 34.1022 155.992 34.7849 155.93 35.4736C154.983 46.5122 150.352 54.0886 143.256 54.0886C133.244 54.0886 126.75 42.8593 126.75 31.8453C126.75 20.8313 133.398 13.132 141.183 14.0483C145.672 14.5648 148.95 16.0531 151.275 18.2423C152.818 19.6998 153.925 21.4524 154.688 23.4449C155.555 25.6834 155.973 28.217 156.059 30.9475C156.059 30.9536 156.065 30.9659 156.065 30.9721C156.108 31.7715 156.102 32.6017 156.065 33.4319H156.059Z"
                fill="#FDA68A"
              />
              <path
                d="M157.504 26.6366C156.127 27.7989 154.405 28.7644 152.474 29.5392C146.97 31.7593 139.818 32.4911 134.246 32.2266C131.51 38.2471 130.397 40.4057 129.702 45.6513C127.027 43.1361 124.844 38.5362 124.198 34.9202C123.558 31.3103 123.62 27.5406 124.628 23.9923C126.215 18.4146 130.2 15.4135 132.961 13.9684C133.65 13.5871 134.271 13.3288 134.751 13.132C138.281 11.7729 142.266 11.8898 145.882 13.0029C147.862 13.6117 149.522 14.6571 150.918 15.9301C153.741 18.4884 155.493 21.9383 156.619 24.5334C156.969 25.3452 157.265 26.0586 157.498 26.6366H157.504Z"
                fill="black"
              />
              <path
                d="M124.172 38.8127C125.236 36.7834 128.181 36.2237 130.758 37.5705C133.335 38.9173 134.565 41.6539 133.501 43.6833C132.437 45.7126 129.491 46.2723 126.915 44.9255C124.338 43.5787 123.108 40.8421 124.172 38.8127Z"
                fill="#FDA68A"
              />
              <path
                d="M137.43 36.6792C139.281 35.6214 140.867 34.9204 143.038 36.1872"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M149.898 36.3594C151.485 35.1787 153.514 35.3755 154.738 36.6792"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M147.918 42.6443C148.847 44.4769 148.441 46.6539 146.461 45.6515"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M146.319 48.5542C143.736 48.9354 141.977 48.1421 140.594 46.248"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M140.98 42.6443C141.404 42.6443 141.748 42.1047 141.748 41.439C141.748 40.7733 141.404 40.2336 140.98 40.2336C140.555 40.2336 140.211 40.7733 140.211 41.439C140.211 42.1047 140.555 42.6443 140.98 42.6443Z"
                fill="black"
              />
              <path
                d="M151.261 42.6443C151.685 42.6443 152.03 42.1047 152.03 41.439C152.03 40.7733 151.685 40.2336 151.261 40.2336C150.836 40.2336 150.492 40.7733 150.492 41.439C150.492 42.1047 150.836 42.6443 151.261 42.6443Z"
                fill="black"
              />
              <path
                d="M126.563 54.6851C130.437 57.477 131.427 62.7472 131.925 67.4886C133.352 81.0916 133.235 94.8545 131.575 108.433C131.237 111.169 130.8 114.023 129.183 116.249C127.11 119.115 123.5 120.406 120.038 121.175C114.811 122.331 109.393 122.645 103.508 121.624C107.751 109.903 109.559 98.8333 109.055 86.3803C108.821 80.8087 108.987 75.7537 109.491 70.1391C110.242 61.7448 116.57 58.215 126.569 54.6851H126.563Z"
                fill="#77136D"
              />
              <path
                d="M149.594 108.55C152.699 107.566 155.817 106.576 159.064 105.573C162.158 105.106 165.435 106.127 167.717 108.494C168.486 109.909 168.941 111.489 169.033 113.094C169.051 113.439 169.057 113.789 168.941 114.109C168.83 114.429 168.572 114.718 168.227 114.792C167.84 114.872 167.446 114.626 167.213 114.312C166.979 113.992 166.874 113.599 166.757 113.217C166.302 111.778 165.589 110.425 164.537 109.134C165.263 110.899 165.232 112.873 165.159 114.773C165.14 115.197 165.116 115.634 164.9 115.997C164.685 116.36 164.23 116.618 163.843 116.464C163.437 116.298 163.301 115.819 163.215 115.394C162.908 113.924 162.588 112.461 162.465 110.788C162.502 112.424 162.459 114.06 162.354 115.696C162.317 116.218 162.25 116.796 161.875 117.159C161.61 117.411 161.21 117.504 160.86 117.411C160.509 117.319 160.214 117.024 160.067 116.612C159.962 116.962 159.851 117.338 159.612 117.608C159.372 117.891 158.966 118.063 158.621 117.934C158.253 117.786 158.086 117.356 157.994 116.962C157.779 116.052 157.687 115.111 157.951 114.423C156.488 115.308 154.729 115.708 153.025 115.548C151.322 115.388 149.674 114.669 148.641 113.543C148.751 111.778 149.139 110.019 149.606 108.556L149.594 108.55Z"
                fill="#FDA68A"
              />
              <path
                d="M115.08 60.4964C112.583 61.7817 110.879 64.2846 110.006 66.9658C109.145 69.6347 109.022 72.4943 109.041 75.2986C109.108 87.8377 110.787 107.418 116.125 110.499C122.619 114.25 138.264 116.237 150.188 115.905C149.924 113.371 149.419 110.548 151.098 108.101C141.197 106.686 128.824 102.252 126.727 100.162C124.273 97.7078 126.044 80.7902 123.757 69.1612C123.093 65.7605 120.295 61.4188 115.067 60.4902L115.08 60.4964Z"
                fill="#77136D"
              />
              <path
                d="M122.578 65.4099C125.229 76.9405 125.382 97.2773 126.68 100.401C127.233 101.742 130.062 102.732 131.778 102.892"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M125.016 82.0385C126.83 78.6562 127.316 76.6883 127.838 72.7402"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M109.93 83.9326C110.852 91.183 111.516 95.8506 112.303 100.106C113.466 106.323 113.576 109.491 115.809 111.711C117.438 113.34 124.215 113.648 129.399 114.201"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M137.876 150.435C127.963 146.327 116.359 143.48 105.203 142.41"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M116.153 144.015C106.024 134.421 91.7879 129.348 78.4062 131.42"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M130.203 147.612C133.954 146.837 137.823 146.634 141.629 147.059C150.565 148.055 159.076 152.483 165.096 159.555"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M160.055 113.414V116.606"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M180.438 120.142C194.28 120.695 235.686 164.487 235.686 164.487"
                stroke="#77136D"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M163.688 145.048C169.745 144.636 181.472 144.033 183.68 144.415"
                stroke="#77136D"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M178.012 69.0815H253.143C255.621 69.0815 257.626 67.0767 257.626 64.5984V51.2414C257.626 48.7631 255.621 46.7583 253.143 46.7583H178.178C175.7 46.7583 173.695 48.7631 173.695 51.2414V64.7706C173.695 67.1505 175.626 69.0815 178.006 69.0815H178.012Z"
                fill="#9C2B5F"
              />
              <path
                d="M252.544 54.2546H177.992"
                stroke="white"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M252.542 61.8801H198.93"
                stroke="white"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M109.535 34.465H34.405C31.9267 34.465 29.9219 32.4603 29.9219 29.9819V16.6249C29.9219 14.1466 31.9267 12.1418 34.405 12.1418H109.369C111.847 12.1418 113.852 14.1466 113.852 16.6249V30.1541C113.852 32.534 111.921 34.465 109.541 34.465H109.535Z"
                fill="#9C2B5F"
              />
              <path
                d="M35 19.6445H109.552"
                stroke="white"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M35 27.27H88.6125"
                stroke="white"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M240.426 50.9031C244.662 50.9031 248.095 47.4698 248.095 43.2345C248.095 38.9993 244.662 35.5659 240.426 35.5659C236.191 35.5659 232.758 38.9993 232.758 43.2345C232.758 47.4698 236.191 50.9031 240.426 50.9031Z"
                fill="#FF9E02"
              />
              <path
                d="M246.787 47.5207C245.409 49.5624 243.072 50.903 240.428 50.903C237.784 50.903 235.465 49.5747 234.088 47.5515C233.842 47.1948 236.191 44.1384 240.428 44.1384C244.665 44.1384 247.021 47.1702 246.787 47.5207Z"
                fill="white"
              />
              <path
                d="M240.427 43.456C242.044 43.456 243.354 42.1455 243.354 40.5288C243.354 38.9121 242.044 37.6016 240.427 37.6016C238.811 37.6016 237.5 38.9121 237.5 40.5288C237.5 42.1455 238.811 43.456 240.427 43.456Z"
                fill="white"
              />
              <path
                d="M248.095 43.2346C248.095 47.4717 244.664 50.9032 240.426 50.9032C236.189 50.9032 232.758 47.4717 232.758 43.2346"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M47.403 15.7149C51.6382 15.7149 55.0716 12.2815 55.0716 8.04629C55.0716 3.81103 51.6382 0.377686 47.403 0.377686C43.1677 0.377686 39.7344 3.81103 39.7344 8.04629C39.7344 12.2815 43.1677 15.7149 47.403 15.7149Z"
                fill="#FECD29"
              />
              <path
                d="M53.7634 12.3264C52.3859 14.3681 50.0491 15.7087 47.4047 15.7087C44.7604 15.7087 42.442 14.3804 41.0644 12.3571C40.8185 12.0005 43.1676 8.94409 47.4047 8.94409C51.6418 8.94409 53.9971 11.9759 53.7634 12.3264Z"
                fill="white"
              />
              <path
                d="M47.4038 8.26779C49.0205 8.26779 50.331 6.95722 50.331 5.34056C50.331 3.72389 49.0205 2.41333 47.4038 2.41333C45.7871 2.41333 44.4766 3.72389 44.4766 5.34056C44.4766 6.95722 45.7871 8.26779 47.4038 8.26779Z"
                fill="white"
              />
              <path
                d="M55.0638 8.04639C55.0638 12.2835 51.6323 15.715 47.3952 15.715C43.1581 15.715 39.7266 12.2835 39.7266 8.04639"
                stroke="black"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M201.109 10.9611L205.15 13.9745L206.952 19.3369L214.436 16.4036L222.473 9.07935L201.109 10.9611Z"
                fill="#FF9E02"
              />
              <path
                d="M207.605 15.4873L206.953 19.337L211.639 17.4982L207.605 15.4873Z"
                fill="#FCBA00"
              />
              <path
                d="M205.15 13.9745L222.473 9.07935L201.109 10.9611L205.15 13.9745Z"
                fill="#FECD29"
              />
              <path
                d="M213.499 19.6936L207.602 15.4873L222.471 9.07935L213.499 19.6936Z"
                fill="#FECD29"
              />
              <path
                d="M23.2625 68.8476L19.0254 72.0023L17.1375 77.6293L9.29053 74.5544L0.859375 66.8735L23.2625 68.8476Z"
                fill="#FF9E02"
              />
              <path
                d="M16.4575 73.5889L17.1401 77.6292L12.2266 75.6982L16.4575 73.5889Z"
                fill="#FCBA00"
              />
              <path
                d="M19.0254 72.0023L0.859375 66.8735L23.2625 68.8476L19.0254 72.0023Z"
                fill="#FECD29"
              />
              <path
                d="M10.2683 78.0044L16.4549 73.5889L0.859375 66.8735L10.2683 78.0044Z"
                fill="#FECD29"
              />
            </svg>
          </div>
          <br />
          <div className="text__in__blank_chat">
            <label className="blank_chat_label">
              <div className="text__in__blank_chat">
                <label
                  className="blank_chat_label"
                  style={{
                    color: "#5A5993",
                    textAlign: "center",
                    fontFamily: "Lexend Deca",
                    fontSize: "23px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                  }}
                >
                  {this.state.BrodCastIs ? (
                    <div>
                      Manage your broadcasts and replies here.
                      <p
                        style={{
                          color: "#5A5993",
                          textAlign: "center",
                          fontFamily: "Lexend Deca",
                          fontSize: "14.37px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "normal",
                        }}
                      >
                        Handle your broadcast conversations here and see what
                        others are saying
                      </p>
                    </div>
                  ) : (
                    <div
                      style={{
                        color: "#5A5993",
                        textAlign: "center",
                        fontFamily: "Lexend Deca",
                        fontSize: "23px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "normal",
                      }}
                    >
                      Manage your replies here.
                      <p
                        style={{
                          color: "#5A5993",
                          textAlign: "center",
                          fontFamily: "Lexend Deca",
                          fontSize: "14.37px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "normal",
                        }}
                      >
                        Handle your conversations here and see what others are
                        saying
                      </p>
                    </div>
                  )}
                </label>
              </div>
            </label>
          </div>
        </div>
      );
    }

    let blockedUser = null;
    let messageList = (
      <CometChatMessageList
        ref={(el) => {
          this.messageListRef = el;
        }}
        lang={this.props.lang}
        messages={this.state.messageList}
        scrollToBottom={this.state.scrollToBottom}
        actionGenerated={this.actionHandler}
      />
    );
    let messageComposer = (
      <CometChatMessageComposer
        ref={(el) => {
          this.composerRef = el;
        }}
        messageToBeEdited={this.state.messageToBeEdited}
        replyPreview={this.state.replyPreview}
        reaction={this.reactionName}
        messageToReact={this.state.messageToReact}
        actionGenerated={this.actionHandler}
        shouldShowInputBox={this.props.shouldShowInputBox}
        isChatingWithSupport={this.props.isChatingWithSupport}
        isRespondingAsAmbassador={this.props.isRespondingAsAmbassador}
      />
    );

    let newMessageIndicator = null;
    if (this.state.unreadMessages.length) {
      const unreadMessageCount = this.state.unreadMessages.length;
      const messageText =
        unreadMessageCount > 1
          ? `${unreadMessageCount} ${Translator.translate(
              "NEW_MESSAGES",
              this.props.lang
            )}`
          : `${unreadMessageCount} ${Translator.translate(
              "NEW_MESSAGE",
              this.props.lang
            )}`;
      newMessageIndicator = (
        <div css={messagePaneTopStyle()} className="message_pane__top">
          <div
            css={messagePaneBannerStyle(this.props)}
            className="message_pane__banner"
          >
            <div
              css={messagePaneUnreadBannerStyle()}
              className="message_pane__unread_banner__banner"
              title={Translator.translate("JUMP", this.props.lang)}
            >
              <button
                type="button"
                css={messagePaneUnreadBannerMessageStyle(this.props)}
                className="message_pane__unread_banner__msg"
                onClick={this.jumpToMessages}
              >
                <span css={iconArrowDownStyle()} className="icon--arrow-down">
                  &#x2193;{" "}
                </span>
                {messageText}
              </button>
            </div>
          </div>
        </div>
      );
    }

    // if sending messages are disabled for chat wigdet in dashboard
    if (
      (this.getContext()?.type === CometChat.ACTION_TYPE.TYPE_USER &&
        this.state.enableSendingOneOnOneMessage === false) ||
      (this.getContext()?.type === CometChat.ACTION_TYPE.TYPE_GROUP &&
        this.state.enableSendingGroupMessage === false)
    ) {
      messageComposer = null;
    }

    if (
      this.getContext()?.type === CometChat.RECEIVER_TYPE.USER &&
      Object.keys(this.getContext().item).length &&
      this.getContext().item.blockedByMe
    ) {
      messageComposer = null;
      messageList = null;
      blockedUser = <CometChatBlockedUser user={this.item} />;
    }

    let liveReactionView = null;
    if (this.state.liveReaction) {
      liveReactionView = (
        <div css={reactionsWrapperStyle()}>
          <CometChatLiveReactions
            reaction={this.reactionName}
            theme={this.props.theme}
            lang={this.props.lang}
          />
        </div>
      );
    }

    /*
		If used as a standalone component
		*/
    let incomingCallView = null;
    let incomingDirectCallView = null;
    if (this.props._parent.trim().length === 0) {
      incomingCallView = (
        <CometChatIncomingCall actionGenerated={this.actionHandler} />
      );

      incomingDirectCallView = (
        <CometChatIncomingDirectCall actionGenerated={this.actionHandler} />
      );
    }

    //don't include it when opened in chat widget
    let outgoingDirectCallView = null;
    let outgoingCallView = null;
    if (Object.keys(this.props.widgetsettings).length === 0) {
      outgoingCallView = (
        <CometChatOutgoingCall
          ref={(el) => (this.outgoingCallRef = el)}
          lang={this.props.lang}
          actionGenerated={this.actionHandler}
        />
      );
      outgoingDirectCallView = (
        <CometChatOutgoingDirectCall
          ref={(el) => (this.outgoingDirectCallRef = el)}
          lang={this.props.lang}
          actionGenerated={this.actionHandler}
        />
      );
    }

    let detailScreen = null;
    if (this.state.viewdetailscreen) {
      if (this.getContext().type === CometChat.ACTION_TYPE.TYPE_USER) {
        detailScreen = (
          <div
            css={chatSecondaryStyle(this.props)}
            className="chat__secondary-view"
          >
            <CometChatUserDetails
              lang={this.props.lang}
              actionGenerated={this.actionHandler}
            />
          </div>
        );
      } else if (this.getContext().type === CometChat.ACTION_TYPE.TYPE_GROUP) {
        detailScreen = (
          <div
            css={chatSecondaryStyle(this.props)}
            className="chat__secondary-view"
          >
            <CometChatGroupDetails
              lang={this.props.lang}
              actionGenerated={this.actionHandler}
            />
          </div>
        );
      }
    }

    let threadMessageView = null;
    if (this.state.threadmessageview) {
      threadMessageView = (
        <div
          css={chatSecondaryStyle(this.props)}
          className="chat__secondary-view"
        >
          <CometChatMessageThread
            activeTab={this.state.activeTab}
            threadItem={this.state.threadmessageitem}
            threadType={this.state.threadmessagetype}
            parentMessage={this.state.threadmessageparent}
            loggedInUser={this.loggedInUser}
            actionGenerated={this.actionHandler}
          />
        </div>
      );
    }

    let originalImageView = null;
    if (this.state.viewOriginalImage) {
      originalImageView = (
        <CometChatImageViewer
          close={() => this.toggleOriginalImageView(false)}
          message={this.state.viewOriginalImage}
        />
      );
    }

    let messageComponent = (
      <React.Fragment>
        <div
          css={chatWrapperStyle(this.props, this.state)}
          className="main__chat"
          dir={Translator.getDirection(this.props.lang)}
        >
          <CometChatMessageHeader
            lang={this.props.lang}
            sidebar={this.props.sidebar}
            viewdetail={this.props.viewdetail === false ? false : true}
            actionGenerated={this.actionHandler}
          />
          <div css={groupuserConatinerList()}>
            <div css={messageContainerList()}>
            {messageList}
            {liveReactionView}
            {messageComposer}
            {blockedUser}
            {newMessageIndicator}
            </div>
            {/* {
              this.getContext().type == "group" ?
                <div css={groupMemberList()}>
                  {
                    this.getContext().item.guid ?
                      <CometChatMemberContainer
                      g_uid={this.getContext().item.guid}
                      actionGenerated={this.actionHandler}
                      />
                    : null
                  }
                </div> : null
              } */}
          </div>
        </div>
        <CometChatToastNotification
          ref={(el) => (this.toastRef = el)}
          lang={this.props.lang}
        />
        {originalImageView}
        {detailScreen}
        {threadMessageView}
        {incomingCallView}
        {outgoingCallView}
        {incomingDirectCallView}
        {outgoingDirectCallView}
      </React.Fragment>
    );

    let messageWrapper = messageComponent;
    /*
		If used as a standalone component
		**/
    if (this.props._parent.trim().length === 0) {
      messageWrapper = (
        <CometChatContextProvider
          ref={(el) => (this.contextProviderRef = el)}
          user={this.props.chatWithUser}
          group={this.props.chatWithGroup}
          language={this.props.lang}
        >
          <div css={chatContainerStyle()}>{messageComponent}</div>
        </CometChatContextProvider>
      );
    }

    return messageWrapper;
  }
}

// Specifies the default values for props:
CometChatMessages.defaultProps = {
  lang: Translator.getDefaultLanguage(),
  theme: theme,
  _parent: "",
  widgetsettings: {},
  chatWithUser: "",
  chatWithGroup: "",
};

CometChatMessages.propTypes = {
  lang: PropTypes.string,
  theme: PropTypes.object,
  _parent: PropTypes.string,
  widgetsettings: PropTypes.object,
  chatWithUser: PropTypes.string,
  chatWithGroup: PropTypes.string,
};

export { CometChatMessages };
