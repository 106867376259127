import { getDataFromParams } from "../../../../../getUrlParams";

export const stickerWrapperStyle = (context, keyframes) => {
	const color = getDataFromParams('fontColor') ?? '#ffffff';
	return {
		borderRadius: "8px",
		width: "320px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		position: "absolute",
		bottom: "50px",
		right: "88px",
		zIndex: "99",
		boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.20)",
	};
};

export const stickerSectionListStyle = (context) => {
	return {
		borderTop: `1px solid ${context.theme.borderColor.primary}`,
		backgroundColor: `${context.theme.backgroundColor.silver}`,
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		textTransform: "uppercase",
		overflowX: "auto",
		overflowY: "hidden",
		padding: "10px",
		"::-webkit-scrollbar": {
			background: `${context.theme.backgroundColor.primary}`,
		},
		"::-webkit-scrollbar-thumb": {
			background: `${context.theme.backgroundColor.silver}`,
		},
	};
};

export const sectionListItemStyle = () => {
	return {
		height: "35px",
		width: "35px",
		cursor: "pointer",
		flexShrink: "0",
		":not(:first-of-type)": {
			marginLeft: "16px",
		},
	};
};

// export const stickerListStyle = () => {
// 	const color = getDataFromParams('fontColor') ?? '#ffffff';
// 	return {
// 		height: "320px",
// 		display: "flex",
// 		overflowX: "hidden",
// 		overflowY: "auto",
// 		flexWrap: "wrap",
// 		justifyContent: "space-between",
// 		alignItems: "center",
// 		borderRadius: "8px",
// 		border: `1px solid ${color}`,
// 		backgroundColor: '#fff',
// 	};
// };
export const stickerListStyle = () => {
  const color = getDataFromParams('fontColor') ?? '#ffffff';
  return {
    // height: "320px",
    // display: "grid",
    // gridTemplateColumns: "repeat(auto-fill, minmax(70px, 1fr))", // Adjust column width as needed
    // gap: "10px", // Adjust gap between stickers
    // overflowY: "auto",
    // borderRadius: "8px",
    // border: `1px solid ${color}`,
    // backgroundColor: '#fff',
    // padding: "10px",
	height: "320px",
    display: "flex",
    overflow: "hidden auto",
    flexWrap: "wrap", // Use camelCase for CSS properties in JavaScript objects
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "8px",
    border: `1px solid ${color}`,
    backgroundColor: '#fff',
    padding: "10px"
  };
};

// export const stickerItemStyle = (context) => {
// 	const mq = [...context.theme.breakPoints];

// 	return {
// 		minWidth: "50px",
// 		minHeight: "50px",
// 		maxWidth: "70px",
// 		maxHeight: "70px",
// 		cursor: "pointer",
// 		flexShrink: "0",
// 		paddignBottom: "5px",
// 		[`@media ${mq[1]}, ${mq[2]}, ${mq[3]}`]: {
// 			maxWidth: "70px",
// 			maxHeight: "70px",
// 		},
// 	};
// };
export const stickerItemStyle = (context) => {
	const mq = [...context.theme.breakPoints];
  
	return {
	//   width: "100%", // Ensure each item takes full width of its container
	//   height: "auto", // Adjust height as needed
	//   cursor: "pointer",
	//   textAlign: "center", // Center sticker within its container
	minWidth: "50px",
    minHeight: "50px",
    maxWidth: "70px",
    maxHeight: "70px",
    cursor: "pointer",
    flexShrink: 0 
	};
  };
  

export const stickerMsgStyle = () => {
	return {
		overflow: "hidden",
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		position: "absolute",
		top: "35%",
	};
};

export const stickerMsgTxtStyle = (context) => {
	return {
		margin: "0",
		height: "30px",
		color: `${context.theme.color.secondary}`,
		fontSize: "24px!important",
		fontWeight: "600",
	};
};

export const stickerCloseStyle = (img, context) => {
	return {
		width: "20px",
		height: "20px",
		borderRadius: "50%",
		alignSelf: "flex-end",
		mask: `url(${img}) center center no-repeat`,
		backgroundColor: `${context.theme.primaryColor}`,
		cursor: "pointer",
		margin: "8px 8px 0 0",
	};
};
