import { getDataFromParams } from "../../../../getUrlParams";

export const listItem = (props, context) => {
	const color = getDataFromParams('fontColor') ?? '#ffffff';
	const selectedState =
		props.selectedGroup && props.selectedGroup.guid === props.group.guid
			? {
				backgroundColor: color,
				color:"#ffffff"
			}
			: {
			};

	return {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		cursor: "pointer",
		width: "auto",
		margin: "10px",
		borderRadius: "10px",
		padding: "8px 16px",
		color:"#494F73",
		...selectedState,
		"&:hover": {
			backgroundColor: color,
			color: "#ffffff !important",
		}
	};
};

export const listItemName = (props, context) => {
	const selectedState =
		props.selectedGroup && props.selectedGroup.guid === props.group.guid
			? {
				color: '#ffffff',
			}
			: {
				color: '',
			};

	return {
		fontSize: "15px",
		fontWeight: "600",
		maxWidth: "calc(100% - 30px)",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		margin: "0",
		width: "105px",
		...selectedState,
		lineHeight: "22px",
	};
};

export const listItemNameTime = (props, context) => {
	const selectedState =
		props.selectedGroup && props.selectedGroup.guid === props.group.guid
			? {
				color: '#ffffff',
			}
			: {
				color: '',
			};

	return {
		fontSize: "14px",
		fontWeight: "300",
		lineHeight: "24px",
		textAlign: "left",
		margin: "0",
		...selectedState,
	};
};

export const listItemIcon = () => {
	return {
		height: "auto",
		fontSize: "14px",
		fontWeight: "300",
		lineHeight: "24px",
		textAlign: "left",
	};
};

export const itemIconStyle = (img, context) => {
	return {
		display: "inline-block",
		width: "24px",
		height: "24px",
		mask: `url(${img}) center center no-repeat`,
		backgroundColor: `${context.theme.secondaryTextColor}`,
	};
};

export const itemThumbnailStyle = () => {
	return {
		display: "inline-block",
		width: "36px",
		height: "36px",
		flexShrink: "0",
	};
};

export const itemDetailStyle = () => {

	return {
		width: "calc(100% - 70px)",
		flexGrow: "1",
		paddingLeft: "16px",
		"&[dir=rtl]": {
			paddingRight: "16px",
			paddingLeft: "0",
		},
		"&:hover": {
			color: "#ffffff !important",
		},
	};
};

export const itemNameWrapperStyle = () => {
	return {
		display: "flex",
		alignItems: "center",
		width: "100%",
		margin: "0",
	};
};

export const itemDescStyle = (props, context) => {
	const selectedState =
		props.selectedGroup && props.selectedGroup.guid === props.group.guid
			? {
				color: '#ffffff',
			}
			: {
				color: '',
			};
	return {
		borderBottom: '0px !important',
		padding: '0 0 5px 0',
		fontSize: '14px',
		fontWeight: '300',
		lineHeight: '24px',
		FontFace: 'Lexend Deca',
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		overflow: "hidden",
		...selectedState,
		// "&:hover": {
		// 	color: '#ffffff',
		// },
	};
};
