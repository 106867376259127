export const avatarStyle = (status) => {
	let borderdcolor = "#CBD6E2";
	if (status === "online" || status === "available") {
		borderdcolor = "#3AA352";
	}
	return {
		border:"2px solid "+borderdcolor,
		borderRadius:"50%",
		padding:"3px",
	};
};

export const memberListData = () => {
	return {
		overflowY: "scroll",
		height: "74%",
	};
};