export const COMETCHAT_CONSTANTS = {
  STAGING: {
    APP_ID: "241639cc23984652",
    REGION: "us",
    AUTH_KEY: "aeab943b9ec8c4b15c332e5deb0feee5b136e29a",
  },
  PRODUCTION: {
    APP_ID: "243298040a731324",
    REGION: "us",
    AUTH_KEY: "3016abed6617c5b01c7552e65505c5304602efba",
  },
};
