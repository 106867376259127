export const sectionStyle = (props) => {
	const containerHeightProp = props.containerHeight
		? {
				height: `calc(100% - ${props.containerHeight})`,
		  }
		: {
				height: "calc(100% - 20px)",
		  };

	return {
		width: "100%",
		borderTop: "1px solid #a6a8d680",
		marginTop:"20px",
		...containerHeightProp,
	};
};

export const sectionHeaderStyle = (props) => {
	return {
		margin: "0",
		width: "100%",
		fontSize: "16px",
		fontWeight: "300!important",
		lineHeight: "24px",
		padding:"20px",
		// color: `${props.theme.color.secondary}`,
		color:  "#494F73",	
		borderTop:"1px solid #CBD6E2",
	
	};
};

export const sectionContentStyle = () => {
	return {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		height: "calc(100% - 20px)",
	};
};

export const mediaBtnStyle = () => {
	return {
		borderBottom: "1px solid var(--grey-50, rgba(166, 168, 214, 0.5))",
		width: "100%",
		clear: "both",
	};
};

export const buttonStyle = (state, type) => {
	const activeBtn =
		state.messagetype === type
			? {
					borderBottom:"1px solid #494F73",
					color:"#494f73",
					fontWeight: "500",
					"&::before": {
						display: "none",
					},
			  }
			: {};

	return {
		display: "inline-block",
		width: "33.33%",
		float: "left",
		fontSize: "14px",
		fontWeight: "300",
		lineHeight: "16px",
		padding: "5px",
		position: "relative",
		textAlign: "center",
		cursor: "pointer",
		color:"#494f73 ",
		...activeBtn,
		"&:before": {
			"`content`": "",
			position: "absolute",
			display: "block",
			width: "2px",
			height: "16px",
			backgroundColor: "rgba(20, 20, 20, 0.12)",
			right: "-2px",
			top: "6px",
		},
		"&:last-of-type::before": {
			display: "none",
		},
	};
};

export const mediaItemStyle = () => {
	return {
		height: "calc(100% - 45px)",
		overflowY: "auto",
		overflowX: "hidden",
		display: "flex",
		flexWrap: "wrap",
		fontSize: "14px",
		padding: "10px",
		paddingBottom: "40px",
		justifyContent: 'center',

		'&::-webkit-scrollbar': {
			width: '3px !important', 
		},
		'&::-webkit-scrollbar-track': {
			background: '#a6a8d640 !important', // Set the background color of the track
			marginTop: '10px', // Add some gap at the top
      		marginBottom: '10px', // Add some gap at the bottom
			marginRight: '10px', 
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: '#9093D8 !important', // Set the color of the scrollbar thumb
			borderRadius: '20px', // Add rounded corners to the thumb
		},
	};
};

export const itemStyle = (state, props, img, context) => {
	let itemTypeStyle = {};
	let bgColor = `${props.theme.backgroundColor.lightGrey}`;

	if (state.messagetype === "image") {
		itemTypeStyle = {
			height: "92px",
			width: "92px",
			backgroundColor: bgColor,
			"> img": {
				display: "block",
				width: "100%",
				height: "100%",
				objectFit: "contain",
			},
		};
	} else if (state.messagetype === "video") {
		itemTypeStyle = {
			"> video": {
				height: "92px",
				width: "92px",
				margin: "auto",
			},
		};
	} else if (state.messagetype === "file") {
		itemTypeStyle = {
			backgroundColor: bgColor,
			"> a": {
				maxWidth: "100%",
				maxHeight: "100%",
				margin: "auto",
				display: "flex",
				padding: "8px",
				"&:hover, &:visited": {
					color: `${props.theme.secondaryTextColor}`,
				},
				"> i": {
					width: "30px",
					height: "24px",
					display: "inline-block",
					mask: `url(${img}) left center no-repeat`,
					backgroundColor: `${context.theme.secondaryTextColor}`,
				},
				"> span": {
					fontSize: "13px",
					color: `${props.theme.secondaryTextColor}`,
					whiteSpace: "pre-wrap",
					wordWrap: "break-word",
					textAlign: "left",
					width: "calc(100% - 30px)",
				},
			},
		};
	}

	return {
		margin: "2px",
		textAlign: "center",
		flex: "1 0 auto",
		...itemTypeStyle,
		"@for $i from 1 through 36": {
			"&:nth-of-type(#{$i})": {
				maxWidth: "100%",
			},
		},
	};
};
