import React from "react";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import { CometChat } from "@cometchat/chat-sdk-javascript";

import { CometChatAvatar } from "../../Shared";
import { CometChatContext } from "../../../util/CometChatContext";

import { theme } from "../../../resources/theme";
import Translator from "../../../resources/localization/translator";
import moment from "moment/moment";
import dateFormat from "dateformat";
import { getMessageSentTime } from "../../../util/common";

import {
	listItem,
	listItemIcon,
	itemIconStyle,
	itemThumbnailStyle,
	itemDetailStyle,
	itemNameWrapperStyle,
	itemDescStyle,
	listItemName,
	listItemNameTime,
} from "./style";

import shieldIcon from "./resources/password-protected-group.svg";
import lockIcon from "./resources/private-group.svg";

class CometChatGroupListItem extends React.PureComponent {
	static contextType = CometChatContext;

	constructor(props) {
		super(props);
		this.state = {
			LastMessage: "",
			LastMessageSender: "",
			lastMessageSentAt: "",
			unreadMessageCount: "",
		}
	}

	componentDidMount(props) {
		// console.log("CometChatGroupListItem: props", this.props.group);
		
		this.fetchLastMessage();
	}

	componentDidUpdate(prevProps) {
		if (this.props.group.guid !== prevProps.group.guid) {
		  this.fetchLastMessage();
		}
	  }

	toggleTooltip = (event, flag) => {
		const elem = event.target;

		const scrollWidth = elem.scrollWidth;
		const clientWidth = elem.clientWidth;

		if (scrollWidth <= clientWidth) {
			return false;
		}

		if (flag) {
			elem.setAttribute("title", elem.textContent);
		} else {
			elem.removeAttribute("title");
		}
	};

	clickHandler = () => {
		this.props.clickHandler(this.props.group);
	};

	fetchLastMessage = async () => {
		const messagesRequest = new CometChat.MessagesRequestBuilder()
		  .setLimit(1)
		  .setGUID(this.props.group.guid)
		  .build();
  
		try {
		  const messages = await messagesRequest.fetchPrevious();
		  if (messages.length > 0) {
			// this.setState({ LastMessage: messages[0].text || messages[0].type });
			this.setState({ lastMessageSentAt: messages[0].rawMessage.sentAt });

			switch (messages[0].type) {
				case "text":
					this.setState({ LastMessage: messages[0].text.toUpperCase() });
					break;
				case "image":
					this.setState({ LastMessage: "Image" });
					break;
				case "audio":
					this.setState({ LastMessage: "Audio" });
					break;
				case "file":
					this.setState({ LastMessage: "File" });
					break;
				case "video":
					this.setState({ LastMessage: "Video" });
					break;
				case "extension_sticker":
					this.setState({ LastMessage: "Sticker" });
					break;
				case "groupMember":
					const memberCount = this.props.group.membersCount;
					const translatedMembers = Translator.translate(
						"MEMBERS",
						this.context.language
					);
					this.setState({ LastMessage: `${memberCount} ${translatedMembers}` });
					break;
				case "extension_poll":
					this.setState({ LastMessage: "Poll" });
					break;
				default:
					this.setState({ LastMessage: messages[0].type });
					break;
			}

			const timestamp = this.getMessageTime(messages[0].rawMessage.sentAt);
			
			this.setState({ lastMessageSentAt: timestamp });
		  }
		} catch (error) {
		  console.log('Message fetching failed with error:', error);
		}
	};

	getMessageTime = (timestamp) => {
		let oTimestamp = null;
		
		const messageTimestamp = new Date(timestamp) * 1000;
		oTimestamp = dateFormat(messageTimestamp, "shortTime");
	
		return oTimestamp;
	};
	
	render() {

		function decrypt(message) {
			let shift = 23;
			let decryptedMessage = "";
			let specialValue = "#$^%&*#$";
			if (!message.includes(specialValue)) {
			  return message;
			} else {
			  message = message.replaceAll("#$^%&*#$", "");
			  for (let i = 0; i < message.length; i++) {
				const charCode = message.charCodeAt(i);
				if (charCode >= 65 && charCode <= 90) {
				  decryptedMessage += String.fromCharCode(
					((charCode - 65 + shift) % 26) + 65
				  );
				} else if (charCode >= 97 && charCode <= 122) {
				  decryptedMessage += String.fromCharCode(
					((charCode - 97 + shift) % 26) + 97
				  );
				} else {
				  decryptedMessage += message[i];
				}
			  }
			  return decryptedMessage;
			}
		}
		
		function decryptedName(name) {
			return decrypt(name);
		  }
	

		let groupTypeIcon = null;
		if (this.props.group.type === CometChat.GROUP_TYPE.PRIVATE) {
			groupTypeIcon = (
				<i
					css={itemIconStyle(shieldIcon, this.context)}
					title={Translator.translate("PRIVATE_GROUP", this.context.language)}
				></i>
			);
		} else if (this.props.group.type === CometChat.GROUP_TYPE.PASSWORD) {
			groupTypeIcon = (
				<i
					css={itemIconStyle(lockIcon, this.context)}
					title={Translator.translate("PROTECTED_GROUP", this.context.language)}
				></i>
			);
		}
		
		return (
			
			<div
				css={listItem(this.props, this.context)}
				className='list__item gruoplist'
				onClick={this.clickHandler}
			>
				<div css={itemThumbnailStyle()} className='list__item__thumbnail' >
					<CometChatAvatar group={this.props.group} />
				</div>
				<div
					css={itemDetailStyle()}
					className='list__item__details'
					dir={Translator.getDirection(this.context.language)}
				>
					<div
						css={itemNameWrapperStyle()}
						className='item__details__name'
						onMouseEnter={(event) => this.toggleTooltip(event, true)}
						onMouseLeave={(event) => this.toggleTooltip(event, false)}
					>
						<div css={listItemName(this.props, this.context)}>{decryptedName(this.props.group.name)}</div>
						<div css={listItemNameTime(this.props, this.context)}>{this.state.lastMessageSentAt}</div>
					</div>
					{/* {console.log(...this.context.)} */}
					<div
						css={itemDescStyle(this.props, this.context)}
						className='item__details__desc'
					>{this.state.LastMessage}
					</div>
				</div>
			</div>
		);
	}
}

// Specifies the default values for props:
CometChatGroupListItem.defaultProps = {
	theme: theme,
	group: {},
	selectedGroup: {},
	clickHandler: () => { },
};

CometChatGroupListItem.propTypes = {
	theme: PropTypes.object,
	selectedGroup: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.shape(CometChat.Group),
	]),
	group: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.shape(CometChat.Group),
	]),
	clickHandler: PropTypes.func,
};

export { CometChatGroupListItem };
