export const groupWrapperStyle = (props, theme) => {
	const borderStyle =
		props._parent === ""
			? {
				border: `1px solid ${theme.borderColor.primary}`,
			}
			: {};

	return {
		display: "flex",
		flexDirection: "column",
		height: "100%",
		boxSizing: "border-box",
		...borderStyle,
		"*": {
			boxSizing: "border-box",
			"::-webkit-scrollbar": {
				width: "3px",
			},
			"::-webkit-scrollbar-track": {
				background: "#CBD6E2",
				marginRight: '10px',
			},
			"::-webkit-scrollbar-thumb": {
				background: "#494F73",
				"&:hover": {
					background: "#aaa",
				},
			},
		},
	};
};

export const groupHeaderStyle = (theme) => {
	return {
		padding: "16px",
		position: "relative",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		borderBottom: `1px solid ${theme.borderColor.primary}`,
		height: "70px",
	};
};

export const groupHeaderCloseStyle = (img, theme) => {
	const mq = [...theme.breakPoints];

	return {
		cursor: "pointer",
		display: "none",
		mask: `url(${img}) left center no-repeat`,
		backgroundColor: `${theme.primaryColor}`,
		height: "24px",
		width: "33%",
		[`@media ${mq[0]}`]: {
			display: "block!important",
		},
	};
};

export const groupHeaderTitleStyle = (props) => {
	const alignment =
		props.hasOwnProperty("enableCloseMenu") && props.enableCloseMenu.length > 0
			? {
				width: "33%",
				textAlign: "center",
			}
			: {};

	return {
		margin: "0",
		display: "inline-block",
		width: "100%",
		textAlign: "left",
		fontSize: "22px",
		fontWeight: "700",
		lineHeight: "26px",
		...alignment,
		"&[dir=rtl]": {
			textAlign: "right",
		},
	};
};

export const groupAddStyle = (img, theme) => {
	return {
		height: "24px",
		cursor: "pointer",
		i: {
			display: "inline-block",
			width: "24px",
			height: "24px",
			mask: `url(${img}) center center no-repeat`,
			backgroundColor: `${theme.primaryColor}`,
		},
	};
};

export const groupSearchStyle = () => {
	return {
		// margin: "16px",
		position: "relative",
		borderRadius: "8px",
		border: '1px solid #CBD6E2',
		// boxShadow: "rgba(20, 20, 20, 0.04) 0 0 0 1px inset",
		backgroundColor: "#ffffff",
		height: "35px",
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	};
};

export const groupSearchButtonStyle = (img, context) => {
	return {
		width: "30px",
		height: "100%",
		padding: "8px 0 8px 8px",
		cursor: "default",
		mask: `url(${img}) 10px center no-repeat`,
		backgroundColor: `#494F73 !important`,
	};
};

export const groupSearchInputStyle = (props) => {
	return {
		width: "100%",
		height: "100%",
		padding: "8px",
		fontSize: "14px",
		fontWeight: "300",
		lineHeight: "24px",
		FontFace: "Lexend Deca",
		outline: "none",
		border: "none",
		borderRadius: "8px",
		color: "#494F73",
		backgroundColor: "#ffffff",
		'&::placeholder' : {
			color: "#494F73",
		}
	};
};

export const groupMsgStyle = () => {
	return {
		overflow: "hidden",
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		position: "absolute",
		top: "50%",
	};
};

export const groupMsgTxtStyle = (theme) => {
	return {
		margin: "0",
		minHeight: "36px",
		color: `${theme.color.secondary}`,
		fontSize: "20px!important",
		fontWeight: "600",
		lineHeight: "30px",
		wordWrap: "break-word",
		padding: "0 16px",
	};
};

export const groupListStyle = () => {
	return {
		height: "calc(100% - 125px)",
		overflowY: "auto",
		margin: "0px 3px 0px 0px",
		padding: "0",
		'&::-webkit-scrollbar': {
			width: '3px !important', 
		},
		'&::-webkit-scrollbar-track': {
			background: '#a6a8d640 !important', // Set the background color of the track
			marginTop: '10px', // Add some gap at the top
      		marginBottom: '10px', // Add some gap at the bottom
			marginRight: '10px', 
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: '#494F73 !important', // Set the color of the scrollbar thumb
			borderRadius: '20px', // Add rounded corners to the thumb
		},
	};
};
