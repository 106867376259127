import { getDataFromParams } from "../../../../getUrlParams";

export const emojiContainerStyle = (props) => {
	const color = getDataFromParams('fontColor') ?? '#ffffff';
	return {
		padding: "1px",
		position: "absolute",
		width: '320px',
		background: '#fff',
		boxShadow: "0px 0px 8px 0px rgba(165, 34, 56, 0.30)",
		zIndex: "9",
		bottom: "50px",
    	border: "1px solid"+color,
    	borderRadius: "8px",
    	right: "69px",
		padding: "5px",
		height:"450px",
		overflow:"auto",
	};
};

export const emojiListStyle = () => {
	return {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "space-evenly",
	};
};

export const emojiCategoryWrapper = () => {
	return {
		justifyContent: "center",
		alignItems: "center",
	};
};

export const emojiCategoryTitle = (props) => {
	return {
		textAlign: "left",
		paddingLeft: "16px",
		paddingTop: "8px",
        color: "#494F73",
		font: props.style.sectionHeaderFont,
	};
};

export const emojiTabLsitStyle = (props) => {
	return {
		width: "100%",
		zIndex: "9",
		display: "flex",
		flexWrap: "wrap",
		padding: "15px 8px",
		position: "sticky",
		bottom: "-5px",
		alignItems: "center",
		justifyContent: "space-around",
		background: props?.style?.background,
	};
};

/**Child props style */
export const getListStyle = () => {
	return {
		width: "24px",
		height: "24px",
		cursor: "pointer",
		alignItems: "center",
		justifyContent: "center",
		iconTint: "#3399ff",
		iconBackground: "rgba(20, 20, 20, 0.58)",
	};
};

export const listStyle = (props) => {
	return {
		padding: "2px",
		display: "flex",
		cursor: "pointer",
		borderRadius: "3px",
		alignItems: "center",
		justifyContent: "center",
		textFont: "700 22px Lexend Deca,sand-serif",
		background: props.style?.background,
	};
};
