import { CometChat } from "@cometchat/chat-sdk-javascript";

export const chatHeaderStyle = (context) => {
	return {
		padding: "16px",
		width: "100%",
		backgroundColor: `${context.theme.backgroundColor.white}`,
		zIndex: "1",
		borderBottom: `1px solid ${context.theme.borderColor.primary}`,
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		height: "65px"
	};
};

export const chatDetailStyle = () => {
	return {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		width: "calc(100% - 116px)",
	};
};

export const chatSideBarBtnStyle = (img, props, context) => {
	const displayValue =
		props.hasOwnProperty("sidebar") && props.sidebar === 0
			? { display: "none!important" }
			: {};

	const mq = [...context.theme.breakPoints];

	return {
		cursor: "pointer",
		display: "none",
		mask: `url(${img}) center center no-repeat`,
		backgroundColor: `${context.theme.primaryColor}`,
		width: "24px",
		height: "24px",
		float: "left",
		[`@media ${mq[1]}, ${mq[2]}`]: {
			display: "block",
		},
		...displayValue,
	};
};

export const chatThumbnailStyle = () => {
	return {
		display: "inline-block",
		width: "40px",
		height: "40px",
		flexShrink: "0",
		margin: "0 16px",
	};
};

export const chatUserStyle = (context) => {
	const mq = [...context.theme.breakPoints];

	return {
		width: "calc(100% - 50px)",
		padding: "0",
		flexGrow: "1",
		display: "flex",
		flexDirection: "column",
		[`@media ${mq[1]}, ${mq[2]}`]: {
			width: "calc(100% - 80px)!important",
		},
	};
};

export const chatNameStyle = (context) => {
	return {
	//   color: context.theme.primary || "#494F73",
	  color:"#494F73",
	  fontFamily: "Lexend Deca",
	  fontSize: 14,
	  fontStyle: "normal",
	  fontWeight: 500,
	  lineHeight: "24px",
	  margin: 0,
	  width: "100%",
	  overflow: "hidden",
	  textOverflow: "ellipsis",
	  whiteSpace: "nowrap",
	  textTransform: "capitalize",
	};
  };
  

export const chatStatusStyle = (state, context) => {
	let status = {};
	if (context.type === CometChat.ACTION_TYPE.TYPE_USER) {
		status = {
			// color: `${context.theme.color.blue}`,
			color:"#494f73",
			// textTransform: "capitalize",
		};

		if (state.presence === "offline") {
			status = {
				// color: `${context.theme.color.helpText}`,
				color:"#494F73",
				// textTransform: "capitalize",
			};
		}

		if (state.typing) {
			status = {
				// color: `${context.theme.color.helpText}`,
				color:"#494F73",
				textTransform: "none",
				fontStyle: "italic",
			};
		}
	} else if (context.type === CometChat.ACTION_TYPE.TYPE_GROUP) {
		status = {
			// color: `${context.theme.color.helpText}`,
			color:"#494F73",
		};

		if (state.typing) {
			status = {
				// color: `${context.theme.color.helpText}`,
				color:"#494F73",
				fontStyle: "italic",
			};
		}
	}

	return {
		fontSize: "14px",
		width: "100%",
		lineHeight:"24px",
		fontWeight:300,
		...status,
	};
};

export const chatOptionWrapStyle = () => {
	return {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		width: "auto",
	};
};

export const chatOptionStyle = (img, context, ongoingCall) => {
	const bgColor = ongoingCall
		? {
			backgroundColor: `${context.theme.secondaryTextColor}`,
		}
		: {
			backgroundColor: `#696BAA`,
		};

	return {
		width: "24px",
		height: "24px",
		cursor: "pointer",
		display: "flex",
		alignItems: "center",
		margin: "0 0 0 16px",
		background: 'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMiAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0xMS42OTc4IDEwLjA2ODhDMTEuNjk3OCA5LjY4MzQ3IDExLjM4NTUgOS4zNzEwOSAxMS4wMDAyIDkuMzcxMDlDMTAuNjE0OSA5LjM3MTA5IDEwLjMwMjUgOS42ODM0NyAxMC4zMDI1IDEwLjA2ODhWMTUuNjUwMkMxMC4zMDI1IDE2LjAzNTUgMTAuNjE0OSAxNi4zNDc4IDExLjAwMDIgMTYuMzQ3OEMxMS4zODU1IDE2LjM0NzggMTEuNjk3OCAxNi4wMzU1IDExLjY5NzggMTUuNjUwMlYxMC4wNjg4WiIgZmlsbD0iIzY5NkJBQSIgc3Ryb2tlPSIjNjk2QkFBIiBzdHJva2Utd2lkdGg9IjAuNSIvPg0KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMSAxQzUuNDc3MTUgMSAxIDUuNDc3MTUgMSAxMUMxIDE2LjUyMjkgNS40NzcxNSAyMSAxMSAyMUMxNi41MjI5IDIxIDIxIDE2LjUyMjkgMjEgMTFDMjEgNS40NzcxNSAxNi41MjI5IDEgMTEgMVpNMi4zOTUzNSAxMUMyLjM5NTM1IDYuMjQ3NzkgNi4yNDc3OSAyLjM5NTM1IDExIDIuMzk1MzVDMTUuNzUyMiAyLjM5NTM1IDE5LjYwNDcgNi4yNDc3OSAxOS42MDQ3IDExQzE5LjYwNDcgMTUuNzUyMiAxNS43NTIyIDE5LjYwNDcgMTEgMTkuNjA0N0M2LjI0Nzc5IDE5LjYwNDcgMi4zOTUzNSAxNS43NTIyIDIuMzk1MzUgMTFaIiBmaWxsPSIjNjk2QkFBIiBzdHJva2U9IiM2OTZCQUEiIHN0cm9rZS13aWR0aD0iMC41Ii8+DQo8cGF0aCBkPSJNMTEuOTMwMyA3LjI3Nzg5QzExLjkzMDMgNy43OTE2NCAxMS41MTM4IDguMjA4MTIgMTEuMDAwMSA4LjIwODEyQzEwLjQ4NjMgOC4yMDgxMiAxMC4wNjk4IDcuNzkxNjQgMTAuMDY5OCA3LjI3Nzg5QzEwLjA2OTggNi43NjQxNCAxMC40ODYzIDYuMzQ3NjYgMTEuMDAwMSA2LjM0NzY2QzExLjUxMzggNi4zNDc2NiAxMS45MzAzIDYuNzY0MTQgMTEuOTMwMyA3LjI3Nzg5WiIgZmlsbD0iIzY5NkJBQSIgc3Ryb2tlPSIjNjk2QkFBIiBzdHJva2Utd2lkdGg9IjAuNSIvPg0KPC9zdmc+") no-repeat center',
	};
};
