// Declare a global gtag function that logs if called before the script loads
let gtag = function () {
    console.log("Google Analytics script not loaded yet.");
  };
  
  // Initialize Google Analytics
  export const initializeAnalytics = (trackingID) => {
    if (!trackingID) {
      console.warn("Tracking ID is not provided");
      return;
    }
  
    // Create and append Google Analytics script
    const gaScript = document.createElement("script");
    gaScript.setAttribute("async", "true");
    gaScript.setAttribute(
      "src",
      `https://www.googletagmanager.com/gtag/js?id=${trackingID}`
    );
    document.head.appendChild(gaScript);
  
    // Create and append script to initialize gtag
    const gaScriptInit = document.createElement("script");
    gaScriptInit.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${trackingID}');
    `;
    document.head.appendChild(gaScriptInit);
  
    // Redefine gtag to point to the new function
    gtag = window.gtag || function () {
      window.dataLayer.push(arguments);
    };
  };  
  export {gtag};

