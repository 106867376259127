import { getDataFromParams } from "../../../../getUrlParams";

export const alertWrapperStyle = (props) => {
	console.log(props);
	
	let pad=props.RemoveDialog ?"0px":"16px";
	if (props?.ShouldLeaveDialog === true) {
		return {
			width: "360px",
			height: "200px",
			backgroundColor: props.theme.backgroundColor.white,
			position: "fixed",
			top: "50%",
			left: "50%",
			transform: "translate(-50%, -50%)",
			padding: pad,
			fontSize: "13px",
			borderRadius: "10px",
			border: "1px solid #eee",
			zIndex: "1000",
			boxShadow: "15px 15px 15px 0px rgba(0, 0, 0, 0.15)",
		};
	}
	return {
		width: "calc(100% - 32px)",
		height: "auto",
		backgroundColor: props.theme.backgroundColor.white,
		position: "absolute",
		margin: "0 auto",
		padding: "16px",
		fontSize: "13px",
		borderRadius: "8px",
		border: "1px solid #eee",
		zIndex: "4",
		top: "50%",
		left: "0",
		right: "0",
		transform: "translateY(-50%)",
	};
};

export const alertMessageStyle = (props) => {
	let mar = props.RemoveDialog ? "45px 10px" : "40px 10px";
	if (props?.ShouldLeaveDialog === true) {
		return {
			textAlign: "center",
			fontFamily: "Lexend Deca",
			fontSize: "16px",
			fontWeight: "700",
			lineHeight: "24px",
			color: "var(--V3-Colors-TL-Main, #494F73)",
			margin: mar,
		};
	}
	return {
		textAlign: "center",
	};
};

export const alertButtonStyle = (props) => {
	let bgtheme=getDataFromParams('fontColor')
	let mar = props.RemoveDialog ? "0 12px" : "0 8px";
	if (props?.ShouldLeaveDialog === true) {
		return {
			display: "flex",
			flexDirection: "row",
			justifyContent: "center",
			alignItems: "center",
			margin: "24px 0 0 0",
			"> button": {
				padding: "0px 10px",
				margin: mar,
				borderRadius: "4px",
				fontSize: "14px",
				cursor: "pointer",
				fontWeight: "500",
				fontFamily: "Lexend Deca",
				height: "40px",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				gap: "10px",
				flex: "1 0 0",
				border: `1px solid ${props.theme.primaryColor}`,
			},
			"> button[value=yes]": {
				// backgroundColor: "var(--V2-Colors-Error, #DF0C34)",
				backgroundColor: bgtheme,
				color: "var(--V2-Colors-White, #FFF)",
				fontFamily: "Lexend Deca",
				fontSize: "14px",
				fontWeight: "500",
				lineHeight: "24px",
			},
			"> button[value=no]": {
				backgroundColor: "#FFF",
				color: "#3E3D56",
				border: "1px solid #3E3D56",
				fontFamily: "Lexend Deca",
				fontSize: "14px",
				fontWeight: "500",
				lineHeight: "24px",
			},
		};
	}
	return {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		margin: "24px 0 0 0",
		"> button": {
			padding: "5px 24px",
			margin: "0 8px",
			borderRadius: "4px",
			fontSize: "12px",
			fontWeight: "600",
			border: `1px solid ${props.theme.primaryColor}`,
		},
		"> button[value=yes]": {
			backgroundColor: props.theme.primaryColor,
			color: props.theme.color.white,
		},
		"> button[value=no]": {
			backgroundColor: props.theme.backgroundColor.secondary,
		},
	};
};
